import { Backdrop, CircularProgress } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import SignatureCanvas from "react-signature-canvas";

export default function Signature({ clear, loading, dispatch }) {
  const [canvasWidth, setCanvasWidth] = useState(200);
  const sigPad = useRef();
  const containerRef = useRef();

  useEffect(() => {
    const updateCanvasSize = () => {
      if (containerRef.current) {
        const width = containerRef.current.getBoundingClientRect().width - 20;
        setCanvasWidth(width);
      }
    };

    window.addEventListener("resize", updateCanvasSize);
    updateCanvasSize(); // Initial size update

    return () => {
      window.removeEventListener("resize", updateCanvasSize);
    };
  }, []);

  useEffect(() => {
    if (clear) {
      sigPad.current.clear();
      dispatch("resign");
    }
  }, [clear]);

  const urltoFile = (url, filename, mimeType) => {
    mimeType = mimeType || (url.match(/^data:([^;]+);/) || "")[1];
    return fetch(url)
      .then(function (res) {
        return res.arrayBuffer();
      })
      .then(function (buf) {
        return new File([buf], filename, { type: mimeType });
      });
  };

  const Trim = () => {
    const url = sigPad.current.getTrimmedCanvas().toDataURL("image/png");
    urltoFile(url, "sign.png").then(function (file) {
      dispatch({
        type: "uploadG2",
        file,
        name: "signature",
        nameFile: "signature",
      });
    });
  };

  return (
    <>
      <div ref={containerRef} style={{ width: "100%", position: "relative" }}>
        <Backdrop
          style={{
            zIndex: 9,
            color: "#1A427D",
            position: "absolute",
            backgroundColor: "rgba(247, 251, 255, 0.9)",
          }}
          open={!!loading}
        >
          <CircularProgress size={25} color="inherit" />
        </Backdrop>
        <SignatureCanvas
          penColor="black"
          onEnd={() => Trim()}
          ref={sigPad}
          canvasProps={{
            width: canvasWidth,
            // width: "100%",
            height: 160,
            className: "sigCanvas",
          }}
        />
      </div>
    </>
  );
}
