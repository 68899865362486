import { Grid } from "@mui/material";
import React from "react";
import { useStyles } from "../styles/footerStyles";

import fbIcon from "../assets/icons/facebook.svg";
import inIcon from "../assets/icons/in-icon.svg";
import logo from "../assets/icons/logo.svg";
import recruitIcon from "../assets/icons/recruit.svg";
import ytIcon from "../assets/icons/youtube.svg";
import zaloIcon from "../assets/icons/zalo.svg";

import addressContact from "../assets/icons/address-contact.svg";
import appStore from "../assets/icons/download-appstore.svg";
import ggPlay from "../assets/icons/download-ggplay.svg";
import emailContact from "../assets/icons/email-contact.svg";
import phoneContact from "../assets/icons/phone-contact.svg";

function Footer({ isMobile, dispatch }) {
  const classes = useStyles();
  const spacing = isMobile ? 1.5 : 2;

  return (
    <div>
      <div className={classes.footer}>
        <Grid container spacing={spacing}>
          <Grid item xs={12} md={2}>
            <div className="logo">
              <img src={logo} alt="logo" />
            </div>
            <div className="mt-10">
              Chuyên cung cấp các giải pháp nền tảng chuyển đổi số trong ngành
              bảo hiểm
            </div>
            <div className="row mt-20">
              <a href="https://www.facebook.com/GlobalCare.vn" target="_bank">
                <img src={fbIcon} alt="fbIcon" className="icon" />
              </a>
              <a
                href="https://www.linkedin.com/company/global-care-company/"
                target="_bank"
              >
                <img src={inIcon} alt="inIcon" className="icon" />
              </a>
              <a href="https://zalo.me/1747508283619492630" target="_bank">
                <img src={zaloIcon} alt="zaloIcon" className="icon" />
              </a>
              <a
                href="https://www.youtube.com/channel/UCVCpGwSPErtw3OyLQYwwulw"
                target="_bank"
              >
                <img src={ytIcon} alt="ytIcon" className="icon" />
              </a>
            </div>
          </Grid>
          <Grid item xs={12} md={3}>
            <div className="title">TÌM HIỂU GLOBAL CARE</div>
            <div className="mt-10">
              <a href="/" target="_bank">
                Trang Chủ
              </a>
            </div>
            <div className="item">
              <a href="https://globalcare.com.vn/ve-chung-toi" target="_bank">
                Giới Thiệu
              </a>
            </div>
            <div className="item">
              <a href="https://globalcare.com.vn/tin-tuc" target="_bank">
                Tin Tức
              </a>
            </div>
            <div className="item">
              <a href="https://globalcare.com.vn/lien-he" target="_bank">
                Liên Hệ
              </a>
            </div>
            <div className="row item">
              <a href="https://globalcare.com.vn/tuyen-dung" target="_bank">
                Tuyển Dụng
              </a>
              <img src={recruitIcon} alt="recruit" className={classes.blink} />
            </div>
            <div className="row item">
              <a href="https://gsale.com.vn/" target="_bank">
                Cộng Tác Viên
              </a>
              <img src={recruitIcon} alt="recruit" className={classes.blink} />
            </div>
            <div className="item">
              <a
                href="https://globalcare.com.vn/cau-hoi-thuong-gap"
                target="_bank"
              >
                Câu Hỏi Thường Gặp
              </a>
            </div>
            <div className="item">Tải Tài Liệu</div>
          </Grid>
          <Grid item xs={12} md={4}>
            <div style={{ marginTop: isMobile ? 0 : 40 }}>
              <a
                href="https://globalcare.com.vn/ung-dung-cong-nghe"
                target="_bank"
              >
                Ứng Dụng Công Nghệ
              </a>
            </div>
            <div className="item">
              <a
                href="https://globalcare.com.vn/san-pham-ca-nhan"
                target="_bank"
              >
                Sản Phẩm Cá Nhân
              </a>
            </div>
            <div className="item">
              <a
                href="https://globalcare.com.vn/san-pham-doanh-nghiep"
                target="_bank"
              >
                Sản Phẩm Doanh Nghiệp
              </a>
            </div>
            <div className="item">
              <a href="https://tracuu.globalcare.vn/" target="_bank">
                Tra Cứu Thông Tin Bồi Thường
              </a>
            </div>
            <div className="item">
              <a
                href="https://globalcare.com.vn/wp-content/uploads/2023/08/QUY-TRINH-GIAI-QUYET-KHIEU-NAI.pdf"
                target="_bank"
              >
                Quy Trình Tiếp Nhận Phản Ánh Khiếu Nại
              </a>
            </div>
            <div className="item">
              <a
                href="https://dev.globalcare.com.vn/wp-content/uploads/2022/07/nghi-dinh-so-03_2021_nd-cp_15.01.2021-1.pdf"
                target="_bank"
              >
                Nghị Định 03/2021/ND-CP có quy định về sử dụng Giấy Chứng Nhận
                Bảo Hiểm Điện Tử
              </a>
            </div>
          </Grid>
          <Grid item xs={12} md={3}>
            <div className={`title ${isMobile ? "mt-20" : ""}`}>
              LIÊN HỆ & HỖ TRỢ
            </div>
            <div className="row mt-10">
              <img src={emailContact} alt="email" className="icon" />
              <a href="mailto:cskh@globalcare.vn" className="email margin-left">
                cskh@globalcare.vn
              </a>
            </div>
            <div className="row mt-15">
              <img src={phoneContact} alt="phone" className="icon" />
              <a href="tel:1900292987" className="tel">
                1900 2929 87
              </a>
            </div>
            <div className="ml-32">
              Tổng đài CSKH: Thứ 2 - Thứ 6 <br /> Từ 9h00 đến 18h00
            </div>
            <div className="row mt-15">
              <img src={addressContact} alt="address" className="icon" />
              <a href="https://goo.gl/maps/TbjLYdDdRG1Uvd8v7" target="_bank">
                1/2 đường số 33, Phường An Khánh, TP. Thủ Đức
              </a>
            </div>

            <div className="title mt-20">TẢI ỨNG DỤNG</div>
            <div className={isMobile ? "" : "row"}>
              <a
                href="https://apps.apple.com/vn/app/gsale/id1578454672"
                target="_bank"
              >
                <img src={appStore} alt="appStore" className="download" />
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=vn.globalcare.isale&hl=vi"
                target="_bank"
                style={{
                  marginLeft: 15,
                }}
              >
                <img src={ggPlay} alt="ggPlay" className="download" />
              </a>
            </div>
          </Grid>
        </Grid>
      </div>
      <div className={classes.reserved}>
        {isMobile ? (
          <>
            <div className="row between">
              <div>
                <div>
                  <a
                    href="https://globalcare.com.vn/wp-content/uploads/2022/09/Dieu-khoan-su-dung.docx.pdf"
                    target="_bank"
                  >
                    Điều khoản sử dụng
                  </a>
                </div>
                <div>
                  <a
                    href="https://cdn.globalcare.vn/private/document/pdf/globalcare/2024-05-06-Chinh-sach-bao-mat-du-lieu-ca-nhan.pdf"
                    target="_bank"
                  >
                    Chính sách bảo mật
                  </a>
                </div>
              </div>
              <div>
                <div>
                  <a
                    href="https://globalcare.com.vn/wp-content/uploads/2023/08/QUY-TRINH-GIAI-QUYET-KHIEU-NAI.pdf"
                    target="_bank"
                  >
                    Chính sách hoàn tiền
                  </a>
                </div>
                <div>
                  <a
                    href="https://globalcare.com.vn/wp-content/uploads/2022/09/Chinh-sach-lien-ket.docx.pdf"
                    target="_bank"
                  >
                    Chính sách liên kết
                  </a>
                </div>
              </div>
            </div>
            <div style={{ marginTop: 15 }}>
              © 2022 Copyright by Global Care Ltd,. All rights reserved
            </div>
          </>
        ) : (
          <>
            <div>© 2022 Copyright by Global Care Ltd,. All rights reserved</div>
            <div className="row">
              <a
                href="https://globalcare.com.vn/wp-content/uploads/2022/09/Dieu-khoan-su-dung.docx.pdf"
                target="_bank"
                className="ml-8"
              >
                Điều khoản sử dụng |
              </a>
              <a
                href="https://globalcare.com.vn/wp-content/uploads/2023/08/QUY-TRINH-GIAI-QUYET-KHIEU-NAI.pdf"
                target="_bank"
                className="ml-8"
              >
                Chính sách hoàn tiền |
              </a>
              <a
                href="https://cdn.globalcare.vn/private/document/pdf/globalcare/2024-05-06-Chinh-sach-bao-mat-du-lieu-ca-nhan.pdf"
                target="_bank"
                className="ml-8"
              >
                Chính sách bảo mật |
              </a>
              <a
                href="https://globalcare.com.vn/wp-content/uploads/2022/09/Chinh-sach-lien-ket.docx.pdf"
                target="_bank"
                className="ml-8"
              >
                Chính sách liên kết
              </a>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default Footer;
