import dayjs from "dayjs";
import { saveAs } from "file-saver";
import { Base64 } from "js-base64";
import _ from "lodash";
import moment from "moment";
import { toast } from "react-toastify";
import api from "../../controller/api";
import {
  consulting,
  createInfoCustomer,
  createOrder,
  getAgeList,
  getDetailByPackagesUid,
  getOTP,
  getPackageDetail,
  getPackageFee,
  getPackageList,
  getProviderList,
  uploadImage,
  verifyOTP,
} from "../../controller/bhskApi";
import { relationship, renderCategoryName } from "../extra/hard-data";
import { getParamUrl, isEmailValid } from "../extra/utils";

export default function bhSKhoeReducer({
  state,
  dispatch,
  action,
  navigate,
  location,
  productSelected,
}) {
  const cases = {
    initial: async () => {
      const { dataSearch } = state;
      const res = {};

      const [ageRes, providerRes] = await Promise.all([
        getAgeList(),
        getProviderList(),
      ]);

      if (ageRes?.statusCode !== 200) {
        toast.error(ageRes?.message || "Có lỗi xảy ra");
      }
      if (providerRes?.statusCode !== 200) {
        toast.error(providerRes?.message || "Có lỗi xảy ra");
      }

      res.ageArr = ageRes?.result || [];
      res.providers = [
        { id: "all", title: "Tất cả" },
        ...(providerRes?.result || []),
      ];
      res.dataBooking = {};
      res.dataReferal = {};

      if (!_.isEmpty(dataSearch)) {
        dispatch("getList");
      }
      return res;
    },

    getList: async () => {
      const { dataSearch, page } = state;
      const res = {};
      const ageParams = !_.isEmpty(dataSearch?.age)
        ? {
            age: {
              value: dataSearch?.age?.value,
              type: dataSearch?.age?.type,
            },
          }
        : {};

      if (_.isEmpty(ageParams)) {
        return { errors: ["ageFilter"] };
      }

      dispatch("onLoading");
      const productParams = {
        productId: 8, //suc khoe
        category: dataSearch?.category,
        providerId:
          dataSearch?.providerId === "all"
            ? null
            : dataSearch?.providerId || null,
        fees: dataSearch?.fees || 1,
        ...ageParams,
        orderBy: dataSearch?.orderBy || 1,
        gender: dataSearch?.gender !== 3 ? dataSearch?.gender : undefined,
        discount: dataSearch?.discount || 0,
        limit: 8,
        offset: 0,
      };
      const resApi = await getPackageList(productParams);
      if (resApi?.statusCode !== 200) {
        toast.error("Lỗi lấy danh sách sản phẩm");
      }
      dataSearch.hasAge = true;
      page.pageNumber = 1;
      page.total = resApi?.result?.total || 0;
      res.data = resApi?.result?.data || [];
      res.loading = false;
      res.errors = [];
      return { ...res, dataSearch, page };
    },

    changePage: async () => {
      const { dataSearch, data, page } = state;
      const res = {};
      dispatch("expanding");
      const ageParams = !_.isEmpty(dataSearch?.age)
        ? {
            age: {
              value: dataSearch?.age?.value,
              type: dataSearch?.age?.type,
            },
          }
        : {};

      const productParams = {
        productId: 8,
        category: dataSearch?.category,
        providerId:
          dataSearch?.providerId === "all"
            ? null
            : dataSearch?.providerId || null,
        fees: dataSearch?.fees || 1,
        ...ageParams,
        orderBy: dataSearch?.orderBy || 1,
        gender: dataSearch?.gender !== 3 ? dataSearch?.gender : undefined,
        discount: dataSearch?.discount || 0,
        limit: page.pageSize || 8,
        offset: page.pageSize * page.pageNumber,
      };
      const resApi = await getPackageList(productParams);
      if (resApi?.statusCode !== 200) {
        toast.error("Lỗi lấy danh sách sản phẩm");
      }
      res.data = [...data, ...resApi?.result?.data];
      page.pageNumber = page.pageNumber + 1;
      page.total = resApi?.result?.total || 0;

      res.expanding = false;
      return { ...res, page };
    },

    resetFilter: () => {
      const { dataSearch } = state;
      dataSearch.category = [];
      dataSearch.providerId = "";
      dataSearch.fees = "";
      dataSearch.age = null;
      return { dataSearch };
    },

    getDetail: async () => {
      dispatch("onLoadDetail");
      const { dataStep2, dataSearch } = state;
      const { benefitsDetailUidList, infoFilter, gender } = action;

      const resApi = await getPackageDetail(
        benefitsDetailUidList,
        infoFilter,
        gender
      );
      if (resApi?.statusCode !== 200) {
        toast.error(resApi?.message || "Có lỗi xảy ra");
        return { loadDetail: false };
      }

      const detailData = resApi?.result?.[0] || {};

      const categories = resApi?.result?.[0]?.infoFilter?.category || [];
      const categoriesName = categories.reduce((item, current) => {
        const name = renderCategoryName(current);
        if (!item.includes(name)) {
          item.push(name);
        }
        return item;
      }, []);

      detailData.categoryFilter = categoriesName.join(", ");
      dataStep2.assessmentQuestion =
        resApi?.result?.[0]?.assessment_question || [];

      const detailEdit = {};
      detailEdit.totalFee = detailData?.totalFeesPackages || 0;
      detailEdit.primary = detailData?.benefits?.primary || [];
      detailEdit.additional = (detailData?.benefits?.additional || [])?.map(
        (b) => ({ ...b, checked: true })
      );

      //Set default value beginDate, gender, dob for OrderDialog
      dispatch({
        type: "onChangeStep1",
        name: "beginDate",
        value:
          detailData?.providerId === 16 //NBH Bao Viet +2days
            ? dayjs().add(2, "day")
            : dayjs().add(1, "day"),
      });
      if (detailData?.gender) {
        dispatch({
          type: "onChangeStep1",
          name: "insuredGender",
          value: detailData?.gender,
        });
      }
      if (dataSearch?.age) {
        // const ageValue = dayjs();
        //PVI's age is rounded up
        // if (detailData?.infoFilter?.providerId === 4) {
        //   ageValue =
        //     dataSearch?.age?.type === "year"
        //       ? dayjs()
        //           .subtract(dataSearch?.age?.value + 1, "year")
        //           .startOf("year")
        //       : dayjs().subtract(1, "year").startOf("year");
        // } else {
        const ageValue =
          dataSearch?.age?.type === "year"
            ? dayjs().subtract(dataSearch?.age?.value, "year").startOf("year")
            : dayjs().startOf("year");
        // }

        dispatch({
          type: "onChangeStep1",
          name: "insuredDob",
          value: ageValue,
        });
      }

      return {
        dataStep2,
        detailData,
        detailEdit,
        loadDetail: false,
      };
    },

    getFee: async () => {
      const { detailEdit, detailData } = state;
      const benefitPrimary = (detailEdit?.primary || []).map((i) => ({
        uid: i.uid,
        value: i?.totalFeesBenefits,
        totalBenefitsFrom:
          i?.edit_total ||
          (i?.categoryFees === "phan_tram" ? i?.totalBenefitsFrom : undefined),
      }));
      const benefitAdditional = (detailEdit?.additional || [])
        .filter((a) => a.checked === true)
        .map((b) => ({
          uid: b.uid,
          value: b?.totalFeesBenefits,
          totalBenefitsFrom:
            b?.edit_total ||
            (b?.categoryFees === "phan_tram"
              ? b?.totalBenefitsFrom
              : undefined),
        }));

      const params = {
        age: detailData?.age,
        gender: detailData?.gender,
        benefitsDetail: [...benefitPrimary, ...benefitAdditional],
      };

      const resApi = await getPackageFee(params);

      if (resApi?.statusCode !== 200) {
        toast.error(resApi?.message || "Có lỗi xảy ra");
      } else {
        detailEdit.totalFee = Math.floor(resApi?.result?.feesPayment || 0);
        detailEdit.newBenefits = resApi?.result?.benefitsDetail || [];
        return { detailEdit };
      }
    },

    checkAdditional: () => {
      const { detailEdit } = state;
      const { checked, index } = action;
      detailEdit.additional[index].checked = checked;
      return { detailEdit };
    },

    checkBenefitAdditional: () => {
      const { checked, index } = action;
      dispatch({ type: "checkAdditional", checked, index });
      dispatch("getFee");
    },

    editDetail: () => {
      const { detailEdit } = state;
      const { index, kind, value } = action;
      detailEdit[kind][index].edit_total = value;
      detailEdit[kind][index].error = false;

      const errorEdit = [];
      const objFee = detailEdit[kind][index];
      const minFeeOfType =
        objFee?.categoryFees !== "phan_tram"
          ? objFee?.totalFeesBenefits
          : objFee?.totalBenefitsFrom;

      const maxFeeOfType =
        objFee?.categoryFees !== "phan_tram"
          ? objFee?.totalFeesBenefits
          : objFee?.totalBenefitsTo;

      if (value < minFeeOfType) {
        errorEdit.push("minErr");
        detailEdit[kind][index].error = true;
      }

      if (value > maxFeeOfType) {
        errorEdit.push("maxErr");
        detailEdit[kind][index].error = true;
      }

      return { detailEdit, errorEdit };
    },

    handleCheck: () => {
      const { dataSearch } = state;
      const { e } = action;
      const category = _.cloneDeep(dataSearch?.category || []);

      if (e.target.checked) {
        category.push(e.target.name);
        dataSearch.category = _.cloneDeep(category);
      } else {
        const filterArr = category.filter((item) => item !== e.target.name);
        dataSearch.category = _.cloneDeep(filterArr);
      }
      return { dataSearch };
    },

    onChangeSearch: () => {
      const { dataSearch } = state;
      const { name, value } = action;
      if (name === "discount") {
        dataSearch[name] = value ? 1 : 0;
      } else {
        dataSearch[name] = value;
      }
      return { dataSearch, errors: [] };
    },

    onChangeStep1: () => {
      const { dataStep1, detailEdit, detailData } = state;
      const { name, value, canRecallDetail } = action;
      dataStep1[name] = value;
      if (name === "insuredDob") {
        const dob = moment(value.format("YYYY/MM/DD"));
        const ageToYear = moment().diff(dob, "years", true);
        if (ageToYear > 0 && ageToYear < 18) {
          dataStep1.under18 = true;
          //
          dataStep1.buyerEmail = dataStep1?.insuredEmail;
          dataStep1.buyerPhone = dataStep1?.insuredPhone;
          dataStep1.buyerAddress = dataStep1?.insuredAddress;
        } else {
          dataStep1.under18 = false;
        }
      }
      if (name === "insuredEmail") {
        dataStep1.buyerEmail = value;
      }
      if (name === "insuredPhone") {
        dataStep1.buyerPhone = value;
      }
      if (name === "insuredAddress") {
        dataStep1.buyerAddress = value;
      }
      if (name === "beginDate") {
        dataStep1.endDate = value
          .add(1, "year")
          .subtract(1, "day")
          .endOf("day");
      }
      detailEdit.canRecallDetail = canRecallDetail;
      return { dataStep1, detailEdit };
    },

    onChangeStep2: () => {
      const { dataStep2 } = state;
      const { index, value } = action;
      dataStep2.assessmentQuestion[index].answer = value === "true";
      return { dataStep2 };
    },

    prevStep: () => {
      const { currentStep, dataStep3 } = state;
      if (currentStep === 3) {
        dataStep3.linkSignImg = null;
      }
      return { currentStep: currentStep - 1 };
    },

    nextStep: () => {
      const { currentStep, dataStep1, dataStep2 } = state;
      if (currentStep === 1) {
        if (!!dataStep1?.notiType) {
          dataStep1.openWarning = true;
          return { dataStep1 };
        }

        const errors = [];
        dataStep1?.insuredPhone &&
          (dataStep1?.insuredPhone || "").length < 10 &&
          errors.push("insuredPhoneValid");

        dataStep1?.insuredPrivateId &&
          ![9, 12].includes((dataStep1?.insuredPrivateId || "").length) &&
          errors.push("insuredPrivateIdValid");

        dataStep1?.insuredEmail &&
          !isEmailValid(dataStep1?.insuredEmail || "") &&
          errors.push("insuredEmailValid");

        const begin = moment(dataStep1?.beginDate.format("YYYY/MM/DD"));
        if (moment().diff(begin, "d") > 0) {
          errors.push("dateValid");
        }

        const birthday = moment(dataStep1?.insuredDob.format("YYYY/MM/DD"));
        if (moment().diff(birthday, "y") > 100) {
          errors.push("dobValid");
        }
        if (moment(birthday).isAfter(moment())) {
          errors.push("dobFuture");
        }

        if (!!dataStep1.under18) {
          dataStep1?.buyerPhone &&
            (dataStep1?.buyerPhone || "").length < 10 &&
            errors.push("buyerPhoneValid");

          dataStep1?.buyerPrivateId &&
            ![9, 12].includes((dataStep1?.buyerPrivateId || "").length) &&
            errors.push("buyerPrivateIdValid");

          dataStep1?.buyerEmail &&
            !isEmailValid(dataStep1?.buyerEmail || "") &&
            errors.push("buyerEmailValid");

          const buyerBirthday = moment(
            dataStep1?.buyerDob.format("YYYY/MM/DD")
          );
          if (moment().diff(buyerBirthday, "y") > 100) {
            errors.push("buyerBirthdayValid");
          }
          if (moment().diff(buyerBirthday, "y") < 18) {
            errors.push("buyerDobValid");
          }
          if (moment(buyerBirthday).isAfter(moment())) {
            errors.push("buyerDobFuture");
          }
        }

        if (errors.length === 0) {
          return { errors, currentStep: 2 };
        }
        return { errors };
      }

      if (currentStep === 2) {
        const errors = [];
        (dataStep2?.assessmentQuestion || []).forEach((q, index) => {
          ![true, false].includes(q?.answer) && errors.push(`ques${index}`);
          !q?.required &&
            q.answer === true &&
            errors.push(`notEnoughCondition`);
        });

        if (errors.length === 0) {
          return { errors, currentStep: 3 };
        }
        return { errors };
      }
    },

    clear: () => ({ clear: true }),

    resign: () => {
      const { dataStep3 } = state;
      dataStep3.linkSignImg = "";
      return { clear: false, dataStep3 };
    },
    uploadG2: async () => {
      const { dataStep3, errors } = state;
      const { file, name, nameFile } = action;
      dispatch("loadingUploadImg");

      var form = new FormData();
      const timestamp = +new Date();
      form.append("storage", "s3");
      form.append("file", file);
      form.append("path", `/user/${nameFile}/${timestamp}-${name}`);

      const response = await uploadImage(form);

      if (!response?.complete) {
        dataStep3.linkSignImg = "";
        dataStep3.loadingImg = false;
        errors.push("linkSignImg");
        return { dataStep3, errors };
      }

      dataStep3.linkSignImg = response?.link || null;
      dataStep3.loadingImg = false;
      return { dataStep3 };
    },

    downloadFile: async () => {
      const { path, name } = action;
      toast.success("Đang tải tài liệu. Vui lòng chờ giây lát...");
      const dataApi = await api.apiGetFileByUrl(path);
      saveAs(dataApi, `${name}`);
    },

    checkAgree: () => {
      const { dataStep3 } = state;
      const { name, value } = action;
      dataStep3[name] = value;
      return { dataStep3 };
    },

    loadingUploadImg: () => {
      const { dataStep3 } = state;
      dataStep3.loadingImg = true;
      return { dataStep3 };
    },

    loadingCreatePDF: () => {
      const { detailData } = state;
      detailData.loadingPDF = action.value;
      return { detailData };
    },

    create: async () => {
      const { dataStep1, dataStep2, dataStep3, detailData, detailEdit } = state;
      const { verifyOTPCode } = action;
      dispatch("openNoti");
      const benefitPrimary = (detailEdit?.primary || []).map((i) => ({
        uid: i.uid,
        value: i?.totalFeesBenefits,
        totalBenefitsFrom:
          i?.edit_total ||
          (i?.categoryFees === "phan_tram" ? i?.totalBenefitsFrom : undefined),
      }));
      const benefitAdditional = (detailEdit?.additional || [])
        .filter((a) => a.checked === true)
        .map((b) => ({
          uid: b.uid,
          value: b?.totalFeesBenefits,
          totalBenefitsFrom:
            b?.edit_total ||
            (b?.categoryFees === "phan_tram"
              ? b?.totalBenefitsFrom
              : undefined),
        }));

      const buyerInfo = dataStep1?.under18
        ? {
            buyerName: dataStep1?.buyerName,
            buyerPrivateId: dataStep1?.buyerPrivateId,
            buyerPhone: dataStep1?.buyerPhone,
            buyerEmail: dataStep1?.buyerEmail,
            buyerGender: dataStep1?.buyerGender
              ? `${dataStep1?.buyerGender}`
              : undefined,
            buyerAddress: dataStep1?.buyerAddress,
            buyerDob: dataStep1?.buyerDob
              ? moment(dataStep1?.buyerDob?.toDate()).format("DD/MM/YYYY")
              : undefined,
          }
        : {};

      const orderData = {
        productCode: "sk",
        productId: detailData?.productId,
        pbpId: detailData?.pbpId,
        providerId: detailData?.providerId,
        ...buyerInfo,
        insuredInfo: {
          insuredName: dataStep1?.insuredName,
          insuredDob: moment(dataStep1?.insuredDob?.toDate()).format(
            "DD/MM/YYYY"
          ),
          insuredPrivateId: dataStep1?.insuredPrivateId,
          insuredPhone: dataStep1?.insuredPhone,
          insuredEmail: dataStep1?.insuredEmail,
          insuredAddress: dataStep1?.insuredAddress,
          insuredGender: dataStep1?.insuredGender
            ? `${dataStep1?.insuredGender}`
            : undefined,
          relationshipWithBuyerId: dataStep1?.under18
            ? +dataStep1?.relationshipWithBuyerId
            : undefined,
          relationshipWithBuyerName: dataStep1?.under18
            ? relationship.find(
                (i) => i.id === dataStep1?.relationshipWithBuyerId
              )?.label
            : undefined,
          // paymentSource: 6,
        },
        extraInfo: {
          otp: verifyOTPCode,
          fees: detailEdit?.totalFee,
          referalCode: dataStep1?.referalCode,
          beginDate: moment(dataStep1?.beginDate?.toDate()).format(
            "DD/MM/YYYY"
          ),
          endDate: moment(dataStep1?.endDate?.toDate()).format("DD/MM/YYYY"),
          assessmentQuestion: dataStep2?.assessmentQuestion,
          signature: dataStep3?.linkSignImg,
        },
        discount: !_.isEmpty(detailData?.discount)
          ? detailData?.discount
          : undefined,
        packages: {
          uid: detailData?.uid,
        },
        benefitsDetail: !_.isEmpty(detailEdit?.newBenefits)
          ? detailEdit?.newBenefits
          : [...benefitPrimary, ...benefitAdditional],
      };

      const resApi = await createOrder(orderData);
      if (resApi?.statusCode !== 200) {
        dataStep3.openNoti = false;
        toast.error(resApi?.message || "Lỗi không tạo được đơn hàng");
        return { dataStep3 };
      }
      dataStep3.openNoti = false;
      dataStep3.paymentLink = resApi?.result?.paymentLink || "";
      return { dataStep3, openSuccess: true };
    },

    selectOTPType: () => {
      const { dataOTP } = state;
      dataOTP.type = action.name;
      dataOTP.isActive = true;
      return { dataOTP };
    },

    getOTPCode: async () => {
      const { dataOTP, dataStep1, detailData } = state;
      const p =
        dataOTP.type === "sms"
          ? { phoneNumber: dataStep1?.insuredPhone }
          : { mailTo: dataStep1?.insuredEmail };
      const data = {
        type: dataOTP.type, // mail hoặc sms
        // merchantId: "",
        packagesTitle: detailData?.packagesTitle,
        productTitle: detailData?.productTitle,
        buyerName: dataStep1?.insuredName,
        ...p,
      };
      const resApi = await getOTP(data);
      if (resApi?.statusCode !== 200) {
        toast.error(resApi?.message || "Không lấy được mã OTP");
      }
    },

    verifyOTPCode: async () => {
      const { dataOTP, dataStep1 } = state;
      const { otp } = action;

      const p =
        dataOTP.type === "sms"
          ? { phoneNumber: dataStep1?.insuredPhone }
          : { mailTo: dataStep1?.insuredEmail };
      const data = {
        type: dataOTP?.type,
        // merchantId: "",
        buyerName: dataStep1?.insuredName,
        reason: "",
        otp,
        ...p,
      };
      const resApi = await verifyOTP(data);
      if (resApi?.statusCode !== 200) {
        return toast.error(resApi?.message || "Mã OTP không hợp lệ");
      }
      dispatch("closeOTPDialog");
      dispatch({ type: "create", verifyOTPCode: otp });
    },

    setSelectItem: () => ({ selectedItem: action.item }),

    openOrderDialog: () => {
      const { selectedItem } = state;

      //set info customer
      const infoCustomer = {};
      const infoToViewPackagesDetail =
        JSON.parse(localStorage.getItem("infoToViewPackagesDetail")) || {};

      if (!_.isEmpty(infoToViewPackagesDetail)) {
        infoCustomer.insuredName = infoToViewPackagesDetail?.customerName;
        infoCustomer.insuredPhone = infoToViewPackagesDetail?.customerPhone;
      }

      //clone old data step1 if old uid = new uid
      const oldOrderData =
        JSON.parse(localStorage.getItem("oldOrderData")) || {};

      const dataStep1 =
        oldOrderData?.packageUid === selectedItem?.uid &&
        oldOrderData?.insuredGender === selectedItem?.gender
          ? {
              ...oldOrderData,
              // insuredDob: oldOrderData?.insuredDob
              //   ? dayjs(oldOrderData?.insuredDob)
              //   : undefined,
              beginDate: oldOrderData?.beginDate
                ? dayjs(oldOrderData?.beginDate)
                : undefined,
              endDate: oldOrderData?.endDate
                ? dayjs(oldOrderData?.endDate)
                : undefined,
              buyerDob: oldOrderData?.buyerDob
                ? dayjs(oldOrderData?.buyerDob)
                : undefined,
              insuredDob: undefined, //reset dob
            }
          : { ...state.dataStep1, ...infoCustomer };

      dataStep1.referalCode = getParamUrl("referalCode");
      return { dataStep1, openOrder: true, openDetail: false };
    },
    closeOrderDialog: () => {
      const res = {};
      const code = getParamUrl("code");
      navigate(`?code=${code}`);
      localStorage.setItem(
        "oldOrderData",
        JSON.stringify({
          ...state.dataStep1,
          packageUid: state.detailData?.uid,
        })
      );

      res.errors = [];
      res.currentStep = 1;
      res.dataStep1 = {};
      res.dataStep2 = {};
      res.dataStep3 = {};
      res.openOrder = false;
      return res;
    },

    openDetailDialog: () => ({ openDetail: true }),
    closeDetailDialog: () => {
      const code = getParamUrl("code");
      navigate(`?code=${code}`);
      return { openDetail: false, detailData: {}, errorEdit: [] };
    },

    openOTPDialog: () => ({ openOTPVerify: true }),
    closeOTPDialog: () => ({ openOTPVerify: false, dataOTP: {} }),

    openNoti: () => {
      const { dataStep3 } = state;
      dataStep3.openNoti = true;
      return { dataStep3, errors: [] };
    },

    onLoading: () => ({ loading: true }),
    onLoadDetail: () => ({ loadDetail: true }),
    expanding: () => ({ expanding: true }),

    onChangeBooking: () => {
      const { dataBooking } = state;
      dataBooking[action.name] = action.value;
      return { dataBooking };
    },
    sendBooking: async () => {
      const { dataBooking, detailData } = state;
      const errors = [];
      dataBooking?.phone &&
        (dataBooking?.phone || "").length < 10 &&
        errors.push("bookingPhone");

      if (errors.length > 0) {
        return { errors };
      }

      dispatch("loadBooking");
      const p = {
        product_id: productSelected.id,
        product_title: productSelected.title,
        provider_id: detailData?.providerId,
        provider_title: detailData?.providerTitle,
        pbp_id: detailData?.pbpId,
        pbp_title: detailData?.productTitle,
        package_uid: detailData?.uid,
        package_title: detailData?.packagesTitle,
        appointment_fullname: dataBooking?.name,
        appointment_phone_number: dataBooking?.phone,
        appointment_time: dataBooking?.time,
        appointment_note: dataBooking?.message,
        appointment_methods: dataBooking?.method,
      };

      const resApi = await consulting(p);
      if (resApi?.statusCode !== 200) {
        toast.error(resApi?.message || "Đặt lịch hẹn không thành công!");
      } else {
        toast.success("Đặt lịch hẹn tư vấn thành công!");
      }
      return { dataBooking: {}, errors: [] };
    },
    openBooking: () => {
      const { dataBooking } = state;
      dataBooking.open = true;
      dataBooking.time = "any";
      dataBooking.method = "zalo";

      //set info customer
      const infoToViewPackagesDetail =
        JSON.parse(localStorage.getItem("infoToViewPackagesDetail")) || {};

      if (!_.isEmpty(infoToViewPackagesDetail)) {
        dataBooking.name = infoToViewPackagesDetail?.customerName;
        dataBooking.phone = infoToViewPackagesDetail?.customerPhone;
      }
      return { dataBooking };
    },
    closeBooking: () => {
      return { dataBooking: {}, errors: [] };
    },
    loadBooking: () => {
      const { dataBooking } = state;
      dataBooking.loading = true;
      return { dataBooking };
    },

    checkDob: () => {
      const errors = [];
      if (action.age > 100) {
        errors.push("dobValid");
      }
      if (moment(action.dob).isAfter(moment())) {
        errors.push("dobFuture");
      }
      return { errors };
    },

    loadingFee: () => {
      const { detailEdit } = state;
      detailEdit.loadingFee = true;
      return { detailEdit };
    },

    recallGetDetailByUid: async () => {
      const { detailData, dataStep1, detailEdit } = state;
      let recall = false;
      let ageToYear = 0;
      let ageToDay = 0;

      const dob = dataStep1?.insuredDob?.toDate();
      const ageValid = moment().diff(dob, "y", true);

      if (isNaN(ageValid) || ageValid > 100) {
        return;
      }

      if (dob && dob != "Invalid Date" && ageValid) {
        // ageToYear =
        //   detailData?.infoFilter?.providerId === 4 //PVI's age is rounded up
        //     ? Math.ceil(moment().diff(dob, "y", true))
        //     :
        //     Math.floor(moment().diff(dob, "y", true));

        ageToYear = Math.floor(moment().diff(dob, "y", true));
        dispatch({ type: "checkDob", age: ageToYear, dob });

        if (ageToYear < 1) {
          ageToDay = Math.floor(moment().diff(dob, "d", true));
        }

        if (
          ageToYear !== detailData?.age?.value &&
          (ageToYear > 0 || ageToDay > 0) &&
          ageToYear < 101
        ) {
          recall = true;
        }
      }

      if (
        dataStep1?.insuredGender
        //&& dataStep1?.insuredGender !== detailData?.gender
      ) {
        recall = true;
      }
      if (recall) {
        dispatch("loadingFee");
        const packagesUid = Base64.encode(
          JSON.stringify(detailData?.uid || "")
        );
        const infoFilter = Base64.encode(
          JSON.stringify({
            age: {
              value:
                ageToDay > 0 ? ageToDay : ageToYear || detailData?.age?.value,
              type: ageToDay > 0 ? "day" : "year",
            },
          })
        );
        const gender = Base64.encode(
          JSON.stringify(dataStep1?.insuredGender || detailData?.gender)
        );

        const resApi = await getDetailByPackagesUid(
          packagesUid,
          infoFilter,
          gender
        );

        if (resApi?.statusCode !== 200) {
          toast.error(resApi?.message);
          detailEdit.loadingFee = false;
          detailEdit.totalFee = 0;
          return { detailEdit };
        } else {
          const newBenefits = resApi?.result?.[0]?.benefits || [];

          if (_.isEmpty(newBenefits?.primary)) {
            dataStep1.openWarning = true;
            dataStep1.notiType = "error";

            detailEdit.loadingFee = false;
            detailEdit.totalFee = 0;
            return { detailEdit, dataStep1 };
          }

          //compare old - new Primary benefit
          const newBenefitPrimary = newBenefits?.primary || [];
          const newBenefitPrimaryUid = newBenefitPrimary.map(
            (b) => b?.benefitsUid
          );

          const missingBenefitPrimary = (detailEdit?.primary || []).filter(
            (item) => !newBenefitPrimaryUid.includes(item?.benefitsUid)
          );
          //get new benefits with the same benefitsUid as the old one
          dataStep1.commonNewBenefitPrimary = newBenefitPrimary.filter(
            (newItem) =>
              (detailEdit?.primary || []).some(
                (oldItem) => oldItem?.benefitsUid === newItem?.benefitsUid
              )
          );

          //compare old - new Additional benefit
          const selectedBenefitAdditional = (
            detailEdit?.additional || []
          ).filter((a) => a.checked === true);

          const newBenefitAdditional = newBenefits?.additional || [];
          const newBenefitAdditionalUid = newBenefitAdditional.map(
            (b) => b?.benefitsUid
          );

          const missingBenefitAdditional = selectedBenefitAdditional.filter(
            (item) => !newBenefitAdditionalUid.includes(item?.benefitsUid)
          );

          //get new benefits checked with the same benefitsUid as the old one
          dataStep1.commonNewBenefitAdditional = newBenefitAdditional
            .filter((newItem) =>
              selectedBenefitAdditional.some(
                (oldItem) => oldItem?.benefitsUid === newItem?.benefitsUid
              )
            )
            .map((item) => ({ ...item, checked: true }));

          const missingAllBenefits = [
            ...missingBenefitPrimary,
            ...missingBenefitAdditional,
          ];
          if (missingAllBenefits.length === 0) {
            //acceptRemovalOfBenefit
            dataStep1.openWarning = false;
            dataStep1.notiType = "";

            detailEdit.canRecallFee = true;
            detailEdit.primary = _.cloneDeep(
              dataStep1?.commonNewBenefitPrimary
            );
            detailEdit.additional = _.cloneDeep(
              dataStep1?.commonNewBenefitAdditional
            );
          } else {
            dataStep1.openWarning = true;
            dataStep1.notiType = "warning";
            dataStep1.missingBenefitTitle = missingAllBenefits
              .map((i) => i?.benefitsTitle)
              .join(", ");
          }

          detailEdit.age = {
            value:
              ageToDay > 0 ? ageToDay : ageToYear || detailData?.age?.value,
            type: ageToDay > 0 ? "day" : "year",
          };
          detailEdit.loadingFee = false;
          return { detailEdit, dataStep1 };
        }
      }
    },

    acceptRemovalOfBenefit: () => {
      const { dataStep1, detailEdit } = state;
      detailEdit.primary = _.cloneDeep(dataStep1?.commonNewBenefitPrimary);
      detailEdit.additional = _.cloneDeep(
        dataStep1?.commonNewBenefitAdditional
      );
      detailEdit.canRecallFee = true;

      dataStep1.openWarning = false;
      dataStep1.notiType = "";
      return { detailEdit, dataStep1 };
    },

    recallGetFee: async () => {
      const { detailData, dataStep1, detailEdit } = state;
      dispatch("loadingFee");
      const benefitPrimary = (detailEdit?.primary || []).map((i) => ({
        uid: i.uid,
        value: i?.totalFeesBenefits,
        totalBenefitsFrom:
          i?.edit_total ||
          (i?.categoryFees === "phan_tram" ? i?.totalBenefitsFrom : undefined),
      }));
      const benefitAdditional = (detailEdit?.additional || []).map((i) => ({
        uid: i.uid,
        value: i?.totalFeesBenefits,
        totalBenefitsFrom:
          i?.edit_total ||
          (i?.categoryFees === "phan_tram" ? i?.totalBenefitsFrom : undefined),
      }));

      const params = {
        age: detailEdit?.age,
        gender: dataStep1?.insuredGender || detailData?.gender,
        benefitsDetail: [...benefitPrimary, ...benefitAdditional],
      };

      const responseFee = await getPackageFee(params);
      if (responseFee?.statusCode !== 200) {
        toast.error(responseFee?.message || "Có lỗi xảy ra");
      } else {
        detailEdit.totalFee = Math.floor(responseFee?.result?.feesPayment || 0);
        detailEdit.newBenefits = [...benefitPrimary, ...benefitAdditional];
      }
      detailEdit.loadingFee = false;
      detailEdit.canRecallFee = false;
      return { detailEdit };
    },

    handleDetail: () => {
      const { selectedItem } = state;
      const params = new URLSearchParams(location.search);

      selectedItem?.benefitsDetailUidList &&
        params.set(
          "benefitsDetailUidList",
          selectedItem?.benefitsDetailUidList
        );
      selectedItem?.infoFilter &&
        params.set("infoFilter", selectedItem?.infoFilter);
      selectedItem?.gender &&
        params.set("gender", Base64.encode(selectedItem?.gender || ""));

      navigate(`${location.pathname}?${params.toString()}`);
      return { dataInfo: {} };
    },

    checkInfoToViewDetail: () => {
      const { item } = action;
      dispatch({ type: "setSelectItem", item });

      const infoToViewPackagesDetail =
        JSON.parse(localStorage.getItem("infoToViewPackagesDetail")) || {};

      if (_.isEmpty(infoToViewPackagesDetail)) {
        return { dataInfo: { open: true } };
      } else {
        dispatch("handleDetail");
      }
    },
    onChangeInfo: () => {
      const { dataInfo } = state;
      const { name, value } = action;
      dataInfo[name] = value;
      return { dataInfo };
    },
    sendInfoToView: async () => {
      const { dataInfo, selectedItem } = state;
      if (dataInfo?.phone && (dataInfo?.phone || "").length < 10) {
        dataInfo.error = true;
        return { dataInfo };
      }

      const data = {
        customerName: dataInfo?.name,
        customerPhone: dataInfo?.phone,
        packageUid: selectedItem?.uid,
      };

      const resApi = await createInfoCustomer(data);
      if (resApi?.statusCode !== 200) {
        return toast.error(resApi?.message || "Lỗi tạo thông tin khách hàng");
      } else {
        localStorage.setItem(
          "infoToViewPackagesDetail",
          JSON.stringify({
            ...data,
            createDate: moment().format("DD/MM/YYYY HH:mm:ss"),
          })
        );

        dispatch("handleDetail");
      }
    },
    closeInfo: () => ({ dataInfo: {} }),

    closeWarning: () => {
      const { dataStep1 } = state;
      dataStep1.openWarning = false;
      return { dataStep1 };
    },
    goBackHome: () => {
      const { dataStep1 } = state;
      dataStep1.openWarning = false;
      dataStep1.notiType = "";
      return { dataStep1, openOrder: false };
    },

    openShare: () => {
      const { data } = state;
      const newArr = data.map((i, idx) => ({
        ...i,
        openShare: idx === action.index,
      }));
      return { data: _.cloneDeep(newArr) };
    },
    closeShare: () => {
      const { data } = state;
      const newArr = data.map(({ openShare, ...i }) => i);
      return { data: _.cloneDeep(newArr) };
    },
    openReferalInfo: () => {
      const { dataReferal, data } = state;
      dataReferal.item = action.item;
      dataReferal.open = true;
      const newArr = data.map(({ openShare, ...i }) => i);
      return {
        dataReferal,
        data: _.cloneDeep(newArr),
      };
    },
    onChangeReferal: () => ({
      dataReferal: { ...state.dataReferal, code: action.value },
    }),

    closeReferalInfo: () => ({ dataReferal: {} }),

    copyReferalLink: () => {
      const { dataReferal } = state;
      const params = new URLSearchParams(location.search);

      dataReferal?.code && params.set("referalCode", dataReferal?.code);
      dataReferal?.item?.benefitsDetailUidList &&
        params.set(
          "benefitsDetailUidList",
          dataReferal?.item?.benefitsDetailUidList
        );
      dataReferal?.item?.infoFilter &&
        params.set("infoFilter", dataReferal?.item?.infoFilter);
      dataReferal?.item?.gender &&
        params.set("gender", Base64.encode(dataReferal?.item?.gender || ""));

      const referalLink = `${window.location.origin}${
        window.location.pathname
      }?${params.toString()}`;

      if (navigator.share) {
        navigator.share({
          title: "Chia sẻ",
          url: referalLink,
        });
      } else {
        navigator.clipboard
          .writeText(referalLink)
          .then(() => {
            toast.success("Đã sao chép link!");
          })
          .catch((err) => {
            toast.error(`Failed to copy link: ${err}`);
          });
      }
      return { dataReferal: {} };
    },

    openErrReport: () => {
      const { dataErrReport, data } = state;
      dataErrReport.item = action.item;
      dataErrReport.open = true;
      const newArr = data.map(({ openShare, ...i }) => i);
      return {
        dataErrReport,
        data: _.cloneDeep(newArr),
      };
    },
    onChangeDataErrReport: () => ({
      dataErrReport: { ...state.dataErrReport, [action.name]: action.value },
    }),

    onLoadingErrImg: () => ({
      dataErrReport: { ...state.dataErrReport, loading: true },
    }),

    addErrImg: async () => {
      const { e } = action;
      const { dataErrReport } = state;

      const file = e.target.files[0];
      if (!file) {
        return;
      }

      const form = new FormData();
      const timestamp = +new Date();
      form.append("storage", "s3");
      form.append("file", file);
      form.append("path", `/user/errReportImg/${timestamp}`);

      dispatch("onLoadingErrImg");
      const response = await uploadImage(form);

      if (!response?.complete) {
        dataErrReport.loading = false;
        toast.error("Không tải được hình ảnh");
        return { dataErrReport };
      }

      dataErrReport.img = [...(state.dataErrReport?.img || []), response?.link];
      dataErrReport.loading = false;
      return { dataErrReport };
    },
    deleteErrReportImg: () => {
      const { dataErrReport } = state;
      dataErrReport.img.splice(action.index, 1);
      return { dataErrReport };
    },
    closeErrReport: () => ({ dataErrReport: {} }),
  };
  return cases[action?.type];
}
