import api from "./api";

export function getAgeList() {
  return api.apiCall("GET", "/api/v1/products-config/age/list");
}

export function getProductList() {
  return api.apiCall("GET", `/api/v1/products-hub/health/website/product/list`);
}

export function getProviderList() {
  return api.apiCall(
    "GET",
    `/api/v1/products-hub/health/website/provider/list`
  );
}

export function getPackageList(data) {
  return api.apiCall(
    "POST",
    "/api/v1/products-hub/health/website/packages/list",
    data
  );
}

export function getPackageDetail(benefitsDetailUidList, infoFilter, gender) {
  return api.apiCall(
    "POST",
    `/api/v1/products-hub/health/website/packages/detail?benefitsDetailUidList=${benefitsDetailUidList}&infoFilter=${infoFilter}&gender=${gender}`
  );
}

export function getPackageFee(data) {
  return api.apiCall(
    "POST",
    "/api/v1/products-hub/health/website/packages/calculate/fees",
    data
  );
}

export function getDetailByPackagesUid(packagesUid, infoFilter, gender) {
  return api.apiCall(
    "POST",
    `/api/v1/products-hub/health/website/packages/detail?packagesUid=${packagesUid}&infoFilter=${infoFilter}&gender=${gender}`
  );
}

export function createOrder(data) {
  return api.apiCall("POST", `/api/v1/products-hub/order/create`, data);
}

export function getOTP(data) {
  return api.apiCall(
    "POST",
    "/api/v1/products-hub/health/website/get-otp",
    data
  );
}

export function verifyOTP(data) {
  return api.apiCall(
    "POST",
    "/api/v1/products-hub/health/website/verify-otp",
    data
  );
}

export function createInfoCustomer(data) {
  return api.apiCall(
    "POST",
    `/api/v1/products-hub/health/website/customer`,
    data
  );
}

export function consulting(data) {
  return api.apiCall(
    "POST",
    "/api/v1/products-hub/consultation-appointments",
    data
  );
}

export function uploadImage(data) {
  return api.apiUpload("POST", "/upload", data);
}
