import { makeStyles } from "@mui/styles";
import logo from "../assets/images/gc-logo.png";

export const useStyles = makeStyles((theme) => ({
  footer: {
    background: `url(${logo}) #F0F7FF 50% / 25% no-repeat`,
    color: "#1A427D",
    padding: "20px 5%",
    fontSize: 14,
    [theme.breakpoints.up("xl")]: {
      padding: "20px 10%",
    },
    [theme.breakpoints.down("sm")]: {
      background: "#F0F7FF",
      padding: "20px 15px",
    },
    "& a": {
      textDecoration: "none",
      color: "#1A427D",
    },
    "& .logo": {
      padding: "10px 0",
      width: "fit-content",
      "& img": {
        width: "90%",
        height: "auto",
      },
    },
    "& .title": {
      fontWeight: 600,
      fontSize: 16,
    },
    "& .row": {
      display: "flex",
      flexDirection: "row",
    },
    "& .email": { textDecoration: "underline" },
    "& .tel": {
      color: "#FF0000",
      fontWeight: 500,
    },
    "& .icon": {
      marginRight: 5,
    },
    "& .item": {
      marginTop: 10,
    },
    "& .ml-32": {
      marginLeft: 32,
    },
    "& .ml-10": {
      marginLeft: 10,
    },
    "& .ml-20": {
      marginLeft: 20,
    },
    "& .mt-15": {
      marginTop: 15,
    },
    "& .mt-10": {
      marginTop: 10,
    },
    "& .mt-20": {
      marginTop: 20,
    },
    "& .download": {
      marginTop: 10,
      width: "100%",
      maxWidth: 160,
      [theme.breakpoints.down("sm")]: {
        width: "40%",
      },
    },
    "& .MuiGrid-root .MuiGrid-item": {
      paddingLeft: 15,
    },
  },
  reserved: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    background: "#1A427D",
    color: "#fff",
    fontSize: 14,
    padding: "15px 5%",

    [theme.breakpoints.up("xl")]: {
      padding: "15px 10%",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "10px 15px",
      flexDirection: "column",
    },

    "& .row": {
      display: "flex",
      flexDirection: "row",
    },
    "& .between": {
      justifyContent: "space-between",
      lineHeight: 1.8,
    },
    "& .ml-8": {
      marginLeft: 8,
    },
    "& a": {
      textDecoration: "none",
      color: "#fff",
    },
  },

  blink: {
    marginLeft: 10,
    animation: "$blinkAnimation 1s linear infinite",
  },
  "@keyframes blinkAnimation": {
    "0%": { opacity: 1 },
    "50%": { opacity: 0 },
    "100%": { opacity: 1 },
  },
}));
