import AddIcon from "@mui/icons-material/Add";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import CloseIcon from "@mui/icons-material/Close";

import {
  Button,
  CircularProgress,
  Dialog,
  Divider,
  TextField,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";

function ErrReports({ dataErrReport, dispatch }) {
  const classes = useStyles();

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={!!dataErrReport?.open}
      className={classes.report}
    >
      <div className="content">
        <div className="row header">
          <div>Báo lỗi</div>
          <CloseIcon onClick={() => dispatch("closeErrReport")} />
        </div>
        <Divider />

        <div>
          <div className="label">
            Nhập tiêu đề <span className="iconRed">*</span>
          </div>
          <TextField
            fullWidth
            size="small"
            variant="outlined"
            value={dataErrReport?.title || ""}
            placeholder="Nhập tiêu đề"
            onChange={(e) =>
              dispatch({
                type: "onChangeDataErrReport",
                name: "title",
                value: e.target.value,
              })
            }
          />
        </div>
        <div>
          <div className="label">
            Nhập nội dung <span className="iconRed">*</span>
          </div>
          <TextField
            fullWidth
            size="small"
            type="tel"
            variant="outlined"
            value={dataErrReport?.content || ""}
            placeholder="Nhập nội dung"
            onChange={(e) =>
              dispatch({
                type: "onChangeDataErrReport",
                name: "content",
                value: e.target.value,
              })
            }
          />
        </div>

        <div key="addImg">
          <div className="label">Hình ảnh lỗi (nếu có)</div>
          <div className="div-img">
            <div
              className={`img ${!dataErrReport?.loading ? "" : "backdrop"}`}
              onClick={() => {
                window.document.getElementById(`addImage`).click();
              }}
            >
              <input
                type="file"
                hidden
                id={`addImage`}
                accept="image/*"
                disabled={dataErrReport?.loading}
                onChange={(e) => {
                  dispatch({ type: "addErrImg", e });
                  e.target.value = null;
                }}
              />
              {dataErrReport?.loading ? (
                <CircularProgress size={20} style={{ color: "#1A427D" }} />
              ) : (
                <AddIcon fontSize="large" style={{ color: "#1A427D" }} />
              )}
            </div>

            {(dataErrReport?.img || [])?.map((img, index) => (
              <div key={index} className="img">
                <img
                  //   onClick={() =>
                  //     dispatch({
                  //       type: "showDialog",
                  //       index,
                  //       listImg: dataErrReport?.img,
                  //     })
                  //   }
                  src={img || ""}
                  width="100%"
                  alt="image"
                />

                <CancelOutlinedIcon
                  className="icon"
                  fontSize="small"
                  style={{ zIndex: 11, color: "#1A427D" }}
                  onClick={() =>
                    dispatch({
                      type: "deleteErrReportImg",
                      index,
                    })
                  }
                />
              </div>
            ))}
          </div>
        </div>

        <div className="action">
          <Button
            fullWidth
            className="btn"
            disabled={!dataErrReport?.title || !dataErrReport?.content}
            // onClick={() => dispatch("sendErrReport")}
          >
            Gửi báo lỗi
          </Button>
        </div>
      </div>
    </Dialog>
  );
}

export default ErrReports;

const useStyles = makeStyles((theme) => ({
  report: {
    fontSize: 16,
    [theme.breakpoints.down("sm")]: {
      fontSize: 14,
    },

    "& .MuiDialog-paper": {
      maxWidth: "470px",
      borderRadius: 16,
      margin: 10,
      [theme.breakpoints.down("sm")]: {
        minWidth: "95vw",
      },
    },
    "& .content": {
      display: "grid",
      gap: 15,
      padding: 25,
      [theme.breakpoints.down("sm")]: {
        padding: "20px 15px",
      },
    },
    "& .row": {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
    },
    "& .header": {
      color: "#1A427D",
      fontWeight: 600,
      fontSize: 20,
      textAlign: "center",
      [theme.breakpoints.down("sm")]: {
        fontSize: 16,
      },
    },

    "& .label": {
      fontWeight: 500,
      marginBottom: "6px",
      color: "#333",
    },
    "& .iconRed": {
      color: "red",
      fontWeight: 500,
    },

    "& .action": {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      marginTop: 10,
    },
    "& .btn": {
      padding: "6px 12px",
      borderRadius: 4,
      textTransform: "none",
      fontSize: 16,
      fontWeight: 500,
      color: "#fff",
      background: "#1A427D",
      border: "1px solid #1A427D",
      "&:hover": {
        color: "#fff",
        background: "#1A427D",
      },
    },
    "& .Mui-disabled": {
      background: "#f1f1f1",
      border: "1px solid #f1f1f1",
    },
    "& .MuiOutlinedInput-input": {
      [theme.breakpoints.down("sm")]: {
        fontSize: 14,
      },
    },

    "& .boxImg": {
      width: "100%",
      margin: "12px 0",
      overflowX: "auto",
    },
    "& .div-img": {
      width: "100%",
      display: "flex",
      alignItems: "center",
      flexWrap: "wrap",
      cursor: "pointer",
    },

    "& .img": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",

      padding: 4,
      margin: "0 8px 8px 0",
      border: "1px solid #D2D2D2",
      borderRadius: 8,

      width: "65px",
      height: "65px",
      position: "relative",

      "& .icon": {
        position: "absolute",
        top: 0,
        right: 0,
      },
    },
    "& .backdrop": {
      backgroundColor: "rgba(105, 105, 105, 0.15)",
    },
  },
}));
