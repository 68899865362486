import { Backdrop, CircularProgress } from "@mui/material";

const Loading = ({ loading, otherStyle }) => {
  return (
    <Backdrop
      style={{
        zIndex: 99,
        color: "#fff",
        position: "absolute",
        ...otherStyle,
      }}
      open={!!loading}
    >
      <CircularProgress size={30} color="inherit" />
    </Backdrop>
  );
};

export default Loading;
