import Grid from "@mui/material/Unstable_Grid2";
import { makeStyles } from "@mui/styles";
import React from "react";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";

const animateRight = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
  transition: { duration: 1 },
};

function Products({ isMobile, state, dispatch }) {
  const { products, productSelected, scrollToBenefit } = state;
  const classes = useStyles();
  const navigate = useNavigate();

  return (
    <div className={classes.container}>
      {scrollToBenefit && isMobile ? (
        <motion.div className="wrapper" {...animateRight}>
          {products.map((i) => (
            <div
              className={
                i?.id === productSelected?.id
                  ? "product-item select"
                  : "product-item unselect"
              }
              onClick={() => {
                dispatch({ type: "selectedProduct", id: i.id });
                navigate(`?code=${i.link}`);
              }}
            >
              <div>
                {!(i?.title || "").includes("BH") ? `BH ${i?.title}` : i?.title}
              </div>
            </div>
          ))}
        </motion.div>
      ) : (
        <motion.div {...animateRight}>
          <Grid container spacing={2} justifyContent={"space-between"}>
            {products.map((i, indx) => (
              <Grid
                xs={4}
                sm={4}
                md={1.3}
                key={indx}
                style={{ display: "flex" }}
              >
                <div
                  className={
                    i?.id === productSelected?.id
                      ? "item select"
                      : "item unselect"
                  }
                  onClick={() => {
                    dispatch({ type: "selectedProduct", id: i.id });
                    navigate(`?code=${i.link}`);
                  }}
                >
                  <img
                    src={
                      i?.id === productSelected?.id ? i?.whiteImg : i?.img || ""
                    }
                    alt={`img-${indx}`}
                    style={{ marginBottom: 8 }}
                  />
                  <div>
                    {!(i?.title || "").includes("BH")
                      ? `BH ${i?.title}`
                      : i?.title}
                  </div>
                </div>
              </Grid>
            ))}
          </Grid>
        </motion.div>
      )}
    </div>
  );
}

export default Products;

const useStyles = makeStyles((theme) => ({
  container: {
    margin: "20px 0",
    [theme.breakpoints.down("sm")]: {
      margin: "15px 0px",
    },

    "& .wrapper": {
      display: "flex",
      flexDirection: "row",
      overflowX: "auto",
      transition: "height 0.5s ease-in-out",
    },
    "& .product-item": {
      minWidth: "70px",
      borderRadius: 12,
      fontSize: 14,

      textAlign: "center",
      alignContent: "center",
      margin: "0 8px 5px 0",
      padding: 8,
      border: "0.5px solid #F7FBFF",
    },

    "& .item": {
      borderRadius: 24,
      fontSize: 14,
      lineHeight: 1.4,
      padding: "10px 5px",
      border: "0.5px solid #F7FBFF",

      flexGrow: 1,
      textAlign: "center",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "flex-start",

      "&:hover": {
        cursor: "pointer",
        border: "0.5px solid #DDDDDD",
      },
    },
    "& .select": {
      background: "#1A427D",
      color: "#fff",
      fontWeight: 500,
    },
    "& .unselect": {
      background: "#F7FBFF",
      color: "#1A427D",
      fontWeight: 400,
    },
  },
}));
