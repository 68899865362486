import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";

import emailIcon from "../assets/icons/email.svg";
import logo from "../assets/icons/logo.svg";
import menuIcon from "../assets/icons/menu.svg";
import phoneIcon from "../assets/icons/phone.svg";
import logoutIcon from "../assets/icons/logout.svg";

const navItems = [
  { id: 1, name: "Trang chủ" },
  { id: 2, name: "Tra cứu thông tin" },
];

function Navbar({ isMobile, state, dispatch }) {
  const { openDrawer } = state;
  const classes = useStyles();

  return (
    <>
      <div className={classes.info}>
        <div className="row">
          <div className="row mr-50">
            <img src={phoneIcon} alt="phone" className="icon" />
            <a href="tel:1900292987">1900 29 29 87</a>
          </div>
          <div className="row">
            <img src={emailIcon} alt="email" className="icon" />
            <a href="mailto:cskh@globalcare.vn">cskh@globalcare.vn</a>
          </div>
        </div>
      </div>
      <div className={classes.nav}>
        <a href="https://globalcare.com.vn" target="_self">
          <img src={logo} alt="logo" className="logo" />
        </a>
        {isMobile ? (
          <img
            src={menuIcon}
            alt="menu"
            className="icon"
            onClick={() => dispatch("setOpenDrawer")}
          />
        ) : (
          <div className="row">
            {navItems.map((i, index) => (
              <div
                key={index}
                className={`nav-item ${i.id === 1 ? "selected" : ""}`}
                onClick={() => dispatch({ type: "selectNav", id: i.id })}
              >
                {i.name}
              </div>
            ))}
          </div>
        )}
      </div>
      <Box component="nav">
        <Drawer
          anchor="right"
          variant="temporary"
          open={openDrawer}
          onClose={() => dispatch("setOpenDrawer")}
          ModalProps={{
            keepMounted: true,
          }}
          className={classes.drawer}
        >
          <Box>
            <div className="header">
              <div></div>
              <CloseIcon onClick={() => dispatch("setOpenDrawer")} />
            </div>
            <List>
              {navItems.map((item) => (
                <ListItem key={item.id} disablePadding>
                  <ListItemButton
                    className={`item ${item.id === 1 ? "active" : "inActive"}`}
                    onClick={() => dispatch({ type: "selectNav", id: item.id })}
                  >
                    <ListItemText primary={item.name} />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
            {/* <div className="logout">
              <div className="logout-div">
                <div className="logout-text"> Đăng xuất</div>
                <img src={logoutIcon} alt="logout" />
              </div>
            </div> */}
          </Box>
        </Drawer>
      </Box>
    </>
  );
}

export default Navbar;

const useStyles = makeStyles((theme) => ({
  info: {
    background: "#1A427D",
    color: "#fff",
    padding: "10px 5%",
    fontSize: 14,

    "& a": {
      textDecoration: "none",
      color: "#fff",
    },
    "& .row": {
      display: "flex",
      flexDirection: "row",
    },
    "& .icon": {
      marginRight: 8,
    },
    "& .mr-50": {
      marginRight: 50,
    },

    [theme.breakpoints.up("xl")]: {
      padding: "10px 10%",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "10px 10px",
      fontSize: 13,
    },
  },

  nav: {
    background: "#fff",
    padding: "10px 5%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    boxShadow: "0px 4px 8px 0px rgba(0, 0, 0, 0.1) ",

    "& .row": {
      display: "flex",
      flexDirection: "row",
    },
    "& .nav-item": {
      marginRight: 30,
      "&:hover": {
        cursor: "pointer",
      },
    },

    "& .logo": {
      width: "50%",
      [theme.breakpoints.down("sm")]: {
        width: "45%",
      },
    },

    "& .selected": {
      fontWeight: 600,
      color: "#1A427D",
    },

    [theme.breakpoints.up("xl")]: {
      padding: "10px 10%",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "10px 15px",
    },
  },

  drawer: {
    display: { xs: "block", lg: "none" },
    "& .MuiDrawer-paper": {
      boxSizing: "border-box",
      width: "240px",
    },
    "& .header": {
      margin: "10px 15px",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
    },
    "& .logo": {
      width: "50%",
    },
    "& .item": {
      textAlign: "end",
      fontWeight: 500,
      margin: "0 20px",
      borderBottom: "0.5px solid #D2D2D2",
    },
    "& .active": {
      color: "#1A427D",
      fontWeight: 600,
    },
    "& .inActive": {
      color: "#4B4B4B",
    },
    "& .MuiListItemButton-root": {
      padding: "10px 0 5px",
    },
    "& .logout": {
      width: "calc(100% - 20px)",
      fontWeight: 500,
      margin: "0 10px",
      padding: "10px 0",
      borderTop: "0.5px solid #D2D2D2",
      position: "absolute",
      right: 0,
      bottom: 10,
    },
    "& .logout-div": {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "flex-end",
    },
    "& .logout-text": {
      color: "#1A427D",
      marginRight: 8,
    },
  },
}));
