import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import CloseIcon from "@mui/icons-material/Close";
import { Button, Dialog, DialogActions, Divider, Slide } from "@mui/material";
import React, { useEffect, useState } from "react";
import OtpInput from "react-otp-input";

import {
  createEventGtag,
  hiddenEmail,
  hiddenPhoneNumber,
} from "../../extra/utils";
import { useStyles } from "../../styles/bhskStyles";

import otpImg from "../../assets/icons/otp-banner.svg";
import otpEmail from "../../assets/icons/otp-email.svg";
import otpPhone from "../../assets/icons/otp-phone.svg";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function OTPVerify({ isMobile, state, dispatch }) {
  const { openOTPVerify, dataOTP, dataStep1 } = state;
  const [timeLeft, setTimeLeft] = useState(120);
  const [otp, setOtp] = useState("");

  const classes = useStyles();

  useEffect(() => {
    // Exit early when we reach 0
    let intervalId = null;
    if (timeLeft <= 0) return;
    if (!dataOTP?.isActive) {
      clearInterval(intervalId);
    }

    if (dataOTP?.isActive) {
      intervalId = setInterval(() => {
        setTimeLeft(timeLeft - 1);
      }, 1000);
    }

    return () => clearInterval(intervalId);
  }, [dataOTP?.isActive, timeLeft]);

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={openOTPVerify}
      TransitionComponent={Transition}
      keepMounted
      className={classes.otpDialog}
    >
      <div className="content">
        <div className="row header">
          <div>Xác thực mã OTP</div>
          <CloseIcon onClick={() => dispatch("closeOTPDialog")} />
        </div>
        <Divider className="my-10" />
        <div className="img">
          <img src={otpImg} alt="otp" />
        </div>
        <Divider className="my-20" />
        {!dataOTP?.type ? (
          <div className="container-otp">
            <div>Vui lòng chọn phương thức xác thực dưới đây:</div>
            <div
              className="row border"
              onClick={() => {
                setTimeLeft(120);
                dispatch({ type: "selectOTPType", name: "sms" });
                dispatch("getOTPCode");
              }}
            >
              <div style={{ display: "flex" }}>
                <img src={otpPhone} alt="otpPhone" className="mr-10" />
                <div>
                  <div>SMS OTP</div>
                  <div className="value">
                    {hiddenPhoneNumber(dataStep1?.insuredPhone || "")}
                  </div>
                </div>
              </div>
              <ArrowForwardIosIcon style={{ fontSize: 16 }} />
            </div>
            <div
              className="row border"
              onClick={() => {
                setTimeLeft(120);
                dispatch({ type: "selectOTPType", name: "mail" });
                dispatch("getOTPCode");
              }}
            >
              <div style={{ display: "flex" }}>
                <img src={otpEmail} alt="otpEmail" className="mr-10" />
                <div>
                  <div>Email OTP</div>
                  <div className="value">
                    {hiddenEmail(dataStep1?.insuredEmail || "")}
                  </div>
                </div>
              </div>
              <ArrowForwardIosIcon style={{ fontSize: 16 }} />
            </div>
          </div>
        ) : (
          <div className="container-otp">
            {dataOTP?.type === "sms" ? (
              <div>
                Vui lòng nhập mã OTP đã được gửi về số điện thoại{" "}
                <b> {hiddenPhoneNumber(dataStep1?.insuredPhone || "")}</b>.
              </div>
            ) : (
              <div>
                Vui lòng nhập mã OTP đã được gửi về email{" "}
                <b> {hiddenEmail(dataStep1?.insuredEmail || "")}</b>.
              </div>
            )}

            <OtpInput
              isInputNum
              value={otp}
              onChange={setOtp}
              numInputs={6}
              renderInput={(props) => (
                <input {...props} inputMode="numeric" pattern="\d*" />
              )}
              inputStyle="otpInput"
              containerStyle={{
                justifyContent: isMobile ? "space-between" : "flex-start",
              }}
            />

            <div className="time-out">Mã OTP sẽ hết hạn trong {timeLeft}s</div>
            <div>
              Chưa nhận được mã?{" "}
              <span
                className="text-color"
                onClick={() => {
                  setTimeLeft(120);
                  dispatch("getOTPCode");
                }}
              >
                Gửi lại mã OTP
              </span>
            </div>
          </div>
        )}

        <Divider className="my-10" />
        <DialogActions className="justify">
          {!dataOTP?.type ? (
            <div></div>
          ) : (
            <div
              className="text-color"
              onClick={() => dispatch({ type: "selectOTPType", name: "" })}
            >
              Chọn phương thức khác
            </div>
          )}

          {!dataOTP?.type ? (
            <Button
              className="action-btn"
              onClick={() => {
                dispatch("closeOTPDialog");
                createEventGtag("health_home_order_otp_back_btn");
              }}
            >
              Quay lại
            </Button>
          ) : (
            <Button
              disabled={otp.length < 6}
              className="action-btn"
              onClick={() => {
                dispatch({ type: "verifyOTPCode", otp });
                createEventGtag("health_home_order_create_after_otp_btn");
              }}
            >
              Xác nhận
            </Button>
          )}
        </DialogActions>
      </div>
    </Dialog>
  );
}

export default OTPVerify;
