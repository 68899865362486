import ClearIcon from "@mui/icons-material/Clear";
import CloseIcon from "@mui/icons-material/Close";

import {
  Button,
  CircularProgress,
  Dialog,
  Divider,
  IconButton,
  InputAdornment,
  Slide,
  TextField,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";

import ticked from "../../assets/icons/ticked.svg";
import { createEventGtag } from "../../extra/utils";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function Booking({ state, dispatch }) {
  const { dataBooking, errors } = state;
  const classes = useStyles();

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={!!dataBooking?.open}
      TransitionComponent={Transition}
      keepMounted
      className={classes.booking}
      onClose={() => dispatch("closeBooking")}
    >
      <div className="content">
        <div className="row header">
          <div></div>
          <div>Đặt lịch hẹn tư vấn</div>
          <CloseIcon onClick={() => dispatch("closeBooking")} />
        </div>
        <Divider />
        <div className="color-text">
          Để lại thông tin để được tư vấn miễn phí cùng chuyên gia.
        </div>

        <div>
          <div className="label">
            Họ và tên <span className="iconRed">*</span>
          </div>
          <TextField
            fullWidth
            size="small"
            variant="outlined"
            value={dataBooking?.name || ""}
            placeholder="Nhập thông tin"
            className="capitalize"
            onChange={(e) =>
              dispatch({
                type: "onChangeBooking",
                name: "name",
                value: e.target.value,
              })
            }
            InputProps={{
              endAdornment: dataBooking?.name ? (
                <ClearInputIcon name={"name"} dispatch={dispatch} />
              ) : null,
            }}
          />
        </div>
        <div>
          <div className="label">
            Số điện thoại <span className="iconRed">*</span>
          </div>
          <TextField
            fullWidth
            size="small"
            type="tel"
            variant="outlined"
            value={dataBooking?.phone || ""}
            placeholder="Nhập thông tin"
            onChange={(e) =>
              dispatch({
                type: "onChangeBooking",
                name: "phone",
                value: e.target.value,
              })
            }
            InputProps={{
              endAdornment: dataBooking?.phone ? (
                <ClearInputIcon name={"phone"} dispatch={dispatch} />
              ) : null,
            }}
          />
          {errors.includes("bookingPhone") && (
            <div className="err">Số điện thoại không đúng định dạng</div>
          )}
        </div>
        <div>
          <div className="label">Phương thức liên hệ:</div>
          <div className="row">
            <div
              className={
                dataBooking?.method === "zalo" ? "border relative" : ""
              }
              onClick={() =>
                dispatch({
                  type: "onChangeBooking",
                  name: "method",
                  value: "zalo",
                })
              }
            >
              <div
                className={dataBooking?.method === "zalo" ? "border bg" : "bg"}
              >
                Zalo
              </div>
              {dataBooking?.method === "zalo" && (
                <img src={ticked} alt="ticked" className="tick-icon" />
              )}
            </div>

            <div
              className={
                dataBooking?.method === "call" ? "border relative" : ""
              }
              onClick={() =>
                dispatch({
                  type: "onChangeBooking",
                  name: "method",
                  value: "call",
                })
              }
            >
              <div
                className={dataBooking?.method === "call" ? "border bg" : "bg"}
              >
                Gọi điện thoại
              </div>
              {dataBooking?.method === "call" && (
                <img src={ticked} alt="ticked" className="tick-icon" />
              )}
            </div>

            <div
              className={
                dataBooking?.method === "meeting" ? "border relative" : ""
              }
              onClick={() =>
                dispatch({
                  type: "onChangeBooking",
                  name: "method",
                  value: "meeting",
                })
              }
            >
              <div
                className={
                  dataBooking?.method === "meeting" ? "border bg" : "bg"
                }
              >
                Gặp trực tiếp
              </div>
              {dataBooking?.method === "meeting" && (
                <img src={ticked} alt="ticked" className="tick-icon" />
              )}
            </div>
          </div>
        </div>
        <div>
          <div className="label">Thời gian hẹn:</div>
          <div className="row">
            <div
              className={dataBooking?.time === "any" ? "border relative" : ""}
              onClick={() =>
                dispatch({
                  type: "onChangeBooking",
                  name: "time",
                  value: "any",
                })
              }
            >
              <div className={dataBooking?.time === "any" ? "border bg" : "bg"}>
                Bất kỳ
              </div>
              {dataBooking?.time === "any" && (
                <img src={ticked} alt="ticked" className="tick-icon" />
              )}
            </div>

            <div
              className={
                dataBooking?.time === "morning" ? "border relative" : ""
              }
              onClick={() =>
                dispatch({
                  type: "onChangeBooking",
                  name: "time",
                  value: "morning",
                })
              }
            >
              <div
                className={dataBooking?.time === "morning" ? "border bg" : "bg"}
              >
                Sáng
              </div>
              {dataBooking?.time === "morning" && (
                <img src={ticked} alt="ticked" className="tick-icon" />
              )}
            </div>

            <div
              className={
                dataBooking?.time === "afternoon" ? "border relative" : ""
              }
              onClick={() =>
                dispatch({
                  type: "onChangeBooking",
                  name: "time",
                  value: "afternoon",
                })
              }
            >
              <div
                className={
                  dataBooking?.time === "afternoon" ? "border bg" : "bg"
                }
              >
                Chiều
              </div>
              {dataBooking?.time === "afternoon" && (
                <img src={ticked} alt="ticked" className="tick-icon" />
              )}
            </div>

            <div
              className={
                dataBooking?.time === "evening" ? "border relative" : ""
              }
              onClick={() =>
                dispatch({
                  type: "onChangeBooking",
                  name: "time",
                  value: "evening",
                })
              }
            >
              <div
                className={dataBooking?.time === "evening" ? "border bg" : "bg"}
              >
                Tối
              </div>
              {dataBooking?.time === "evening" && (
                <img src={ticked} alt="ticked" className="tick-icon" />
              )}
            </div>
          </div>
        </div>
        <div>
          <div className="label">Lời nhắn</div>
          <TextField
            fullWidth
            size="small"
            variant="outlined"
            value={dataBooking?.message || ""}
            placeholder="Nhập thông tin"
            onChange={(e) =>
              dispatch({
                type: "onChangeBooking",
                name: "message",
                value: e.target.value,
              })
            }
            InputProps={{
              endAdornment: dataBooking?.message ? (
                <ClearInputIcon name={"message"} dispatch={dispatch} />
              ) : null,
            }}
          />
        </div>
        <Button
          className="btn"
          disabled={
            !dataBooking?.name || !dataBooking?.phone || dataBooking.loading
          }
          onClick={() => {
            dispatch("sendBooking");
            createEventGtag("health_home_appointment_btn");
          }}
        >
          {dataBooking.loading ? (
            <div>
              <CircularProgress size={14} color="inherit" /> Đang gửi thông
              tin...
            </div>
          ) : (
            "Gửi thông tin tư vấn"
          )}
        </Button>
      </div>
    </Dialog>
  );
}

export default Booking;

const ClearInputIcon = ({ name, dispatch }) => (
  <InputAdornment position="end">
    <IconButton
      size="small"
      onClick={() => dispatch({ type: "onChangeBooking", name, value: null })}
    >
      <ClearIcon style={{ fontSize: 14 }} />
    </IconButton>
  </InputAdornment>
);

const useStyles = makeStyles((theme) => ({
  booking: {
    fontSize: 16,
    [theme.breakpoints.down("sm")]: {
      fontSize: 14,
    },

    "& .MuiDialog-paper": {
      maxWidth: "450px",
      borderRadius: 16,
      margin: 10,
      [theme.breakpoints.down("sm")]: {
        minWidth: "95vw",
      },
      [theme.breakpoints.up("sm")]: {
        position: "fixed",
        bottom: "2%",
        right: "5%",
      },
    },
    "& .content": {
      display: "grid",
      gap: 15,
      padding: 20,
      [theme.breakpoints.down("sm")]: {
        padding: "20px 10px",
      },
    },
    "& .row": {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
    },
    "& .header": {
      color: "#1A427D",
      fontWeight: 600,
      fontSize: 20,
      [theme.breakpoints.down("sm")]: {
        fontSize: 16,
      },
    },

    "& .color-text": {
      color: "#FB7800",
      textAlign: "justify",
    },
    "& .label": {
      fontWeight: 500,
      marginBottom: "6px",
      color: "#333",
    },
    "& .iconRed": {
      color: "red",
      fontWeight: 500,
    },
    "& .capitalize": {
      textTransform: "capitalize",
      "& .MuiOutlinedInput-input": {
        textTransform: "capitalize",
      },
    },

    "& .relative": { position: "relative", overflow: "hidden" },
    "& .border": {
      border: "1px solid #1A427D",
      color: "#343A40",
      borderRadius: 4,
      background: "#fff !important",
    },
    "& .bg": {
      border: "0.5px solid #F8F9FA",
      background: "#F8F9FA",
      color: "#646D69",
      padding: "10px 20px",
      borderRadius: 4,
      fontWeight: 500,
      "&:hover": {
        cursor: "pointer",
        border: "0.5px solid #DDDDDD",
      },
      [theme.breakpoints.down("sm")]: {
        padding: "8px 15px",
      },
    },
    "& .tick-icon": {
      position: "absolute",
      bottom: 0,
      right: 0,
    },
    "& .btn": {
      padding: "6px 12px",
      borderRadius: 4,
      textTransform: "none",
      fontSize: 16,
      fontWeight: 500,
      color: "#fff",
      background: "#1A427D",
      border: "1px solid #1A427D",
      "&:hover": {
        color: "#fff",
        background: "#1A427D",
      },
    },
    "& .Mui-disabled": {
      background: "#f1f1f1",
      border: "1px solid #f1f1f1",
    },
    "& .err": {
      color: "red",
      fontSize: 13,
      marginTop: "4px",
    },
    "& .MuiOutlinedInput-input": {
      [theme.breakpoints.down("sm")]: {
        fontSize: 14,
      },
    },
  },
}));
