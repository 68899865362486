import CloseIcon from "@mui/icons-material/Close";
import { Button, Dialog, Slide, TextField } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import { createEventGtag } from "../../extra/utils";

function ReferalInfo({ dataReferal, dispatch }) {
  const classes = useStyles();

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={!!dataReferal?.open}
      className={classes.referal}
    >
      <div className="content">
        <div className="row header">
          <div>
            Nhập mã giới thiệu{" "}
            <span className="italic">(dành cho cộng tác viên)</span>
          </div>
          <CloseIcon
            onClick={() => {
              dispatch("closeReferalInfo");
              createEventGtag("health_home_referal_get_link_btn");
            }}
          />
        </div>

        <TextField
          fullWidth
          size="small"
          variant="outlined"
          value={dataReferal?.code || ""}
          placeholder="Nhập mã giới thiệu "
          onChange={(e) =>
            dispatch({
              type: "onChangeReferal",
              value: e.target.value,
            })
          }
        />

        <div className="action">
          <Button className="btn" onClick={() => dispatch("copyReferalLink")}>
            Lấy link
          </Button>
        </div>
      </div>
    </Dialog>
  );
}

export default ReferalInfo;

const useStyles = makeStyles((theme) => ({
  referal: {
    fontSize: 16,
    [theme.breakpoints.down("sm")]: {
      fontSize: 14,
    },

    "& .MuiDialog-paper": {
      maxWidth: "450px",
      borderRadius: 16,
      margin: 10,
      [theme.breakpoints.down("sm")]: {
        minWidth: "95vw",
      },
    },
    "& .content": {
      display: "grid",
      gap: 15,
      padding: 25,
      [theme.breakpoints.down("sm")]: {
        padding: "20px 15px",
      },
    },
    "& .row": {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
    },
    "& .header": {
      fontSize: 20,
      fontWeight: 600,
      [theme.breakpoints.down("sm")]: {
        fontSize: 16,
      },
    },
    "& .italic": {
      fontSize: 15,
      fontWeight: 400,
      fontStyle: "italic",
    },

    "& .action": {
      display: "flex",
      justifyContent: "center",
    },
    "& .btn": {
      width: "25%",
      padding: "4px 8px",
      borderRadius: 4,
      textTransform: "none",
      fontSize: 16,
      fontWeight: 500,
      color: "#fff",
      background: "#1A427D",
      border: "1px solid #1A427D",
      "&:hover": {
        color: "#fff",
        background: "#1A427D",
      },
    },
    "& .Mui-disabled": {
      background: "#f1f1f1",
      border: "1px solid #f1f1f1",
    },
    "& .MuiOutlinedInput-input": {
      [theme.breakpoints.down("sm")]: {
        fontSize: 14,
      },
    },
  },
}));
