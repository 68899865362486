import axios from "axios";
import { Base64 } from "js-base64";

const API_URL = process.env.REACT_APP_CORE_SERVER;
const API_UPLOAD = process.env.REACT_APP_G2DOMAIN;

const getParamUrl = () => {
  let search = window.location.search;
  let arr = search.split("?");
  let params = new URLSearchParams(arr[1]);
  let token = params.get("token");
  return token;
};

const Header = {
  Authorization: `bearer ${getParamUrl()}`,
};

async function apiCall(method, url, data) {
  // var data_base64 = Base64.encode(JSON.stringify(data));

  try {
    const res = await axios({
      method: method,
      url: API_URL + url,
      // data: {
      //   data: data_base64,
      // },
      data,
      // headers: Header,
    });
    return res?.data;
  } catch (err) {
    return err?.response?.data;
  }
}

async function apiUpload(method, url, data) {
  try {
    const res = await axios({
      method: method,
      url: API_UPLOAD + url,
      data: data,
      headers: Header,
    });
    return res.data;
  } catch (err) {
    return err?.response?.data?.message || "Có lỗi xảy ra";
  }
}

async function apiGetFileByUrl(url) {
  try {
    const res = await axios({
      method: "GET",
      url: url,
      responseType: "blob",
    });
    return res.data;
  } catch (err) {
    return err?.response?.data?.message || "Có lỗi xảy ra";
  }
}

const api = { apiCall, apiUpload, apiGetFileByUrl };
export default api;
