import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  body: {
    position: "relative",
  },
  page: {
    color: "#343A40",
    fontSize: 16,
    lineHeight: 1.4,
    fontFamily: "Roboto",
  },
  scrollTopBtn: {
    position: "fixed",
    right: "5px",
    bottom: "20px",
    cursor: "pointer",
  },
}));
