import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Button, Dialog, DialogContent, Slide } from "@mui/material";
import { makeStyles } from "@mui/styles";
import * as React from "react";

import { createEventGtag } from "../../extra/utils";
import success from "../../assets/icons/success.svg";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function SuccessDialog({ state, dispatch }) {
  const { openSuccess, dataStep3 } = state;
  const classes = makeStyle();

  const nextPayment = (link) => {
    if (!link) return;
    window.location.replace(
      `${link}&callback=${encodeURIComponent(window.location.href)}`
    );
  };

  return (
    <Dialog
      open={openSuccess}
      TransitionComponent={Transition}
      keepMounted
      className={classes.dialog}
    >
      <DialogContent className={classes.content}>
        <img src={success} alt="success-img" />
        <div className="title">Tạo đơn hàng thành công</div>
        <div className="action">
          Quý khách vui lòng tiến hành thanh toán đơn hàng này
        </div>
        <Button
          className="btn"
          onClick={() => {
            createEventGtag("health_home_order_next_payment_btn");
            nextPayment(dataStep3?.paymentLink);
          }}
        >
          Thanh toán
          <NavigateNextIcon />
        </Button>
      </DialogContent>
    </Dialog>
  );
}

export default SuccessDialog;

const makeStyle = makeStyles(() => ({
  dialog: {
    "& .MuiPaper-root.MuiPaper-elevation": {
      borderRadius: 5,
      width: "80%",
      maxWidth: "400px",
      margin: 0,
    },
  },

  content: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    "& .title": {
      textAlign: "center",
      color: "#1A427D",
      margin: 10,
      fontWeight: 600,
      fontSize: 20,
    },
    "& .action": {
      textAlign: "center",
      fontSize: "14px",
      color: "#FB7800",
    },
    "& .btn": {
      marginTop: 15,
      padding: "6px 12px",
      width: "50%",
      borderRadius: 8,
      color: "#fff",
      background: "#1A427D",
      border: "1px solid #1A427D",
      textTransform: "none",
      "&:hover": {
        color: "#fff",
        background: "#1A427D",
      },
    },
  },
}));
