import { makeStyles } from "@mui/styles";
import _ from "lodash";
import moment from "moment";
import React from "react";
import logoGC from "../../assets/images/logo.png";
import { formatMoney } from "../../extra/utils";

const ConvertPDF = React.forwardRef((props, ref) => {
  const { data, detailEdit } = props;
  const classes = useStyles();
  return (
    <div ref={ref} className={classes.page}>
      <div className={classes.logo}>
        <img src={logoGC} alt="logo" className="img" />
      </div>
      <div className={classes.title}>BẢN CHÀO BẢO HIỂM SỨC KHOẺ</div>
      <div className={classes.subTitle}>
        (Bảng chào phí chỉ có giá trị tham khảo, quyền lợi bảo hiểm và phí bảo
        hiểm thực tế sẽ được cập nhật tại hệ thống báo giá Global Care tại thời
        điểm mua hàng)
      </div>
      <div className={classes.dateTime}>
        {`Ngày ${moment().format("DD")} tháng ${moment().format(
          "MM"
        )} năm ${moment().format("YYYY")}`}
      </div>
      <div className={classes.row}>
        <div className="label">Sản phẩm bảo hiểm</div>
        <div className="text-bold">{data?.productTitle || ""}</div>
      </div>
      <div className={classes.row}>
        <div className="label">Bên mua bảo hiểm</div>
        <div className="text-bold"></div>
      </div>
      <div className={classes.row}>
        <div className="label">Ngày sinh</div>
        <div className="value"></div>
      </div>
      <div className={classes.row}>
        <div className="label">Tuổi tham gia</div>
        <div className="value">
          {data?.infoFilter?.age?.value > 100
            ? "Dưới 1 tuổi"
            : `${data?.infoFilter?.age?.value || "........"} ${
                data?.infoFilter?.age?.type === "day" ? "ngày tuổi" : "tuổi"
              }`}
        </div>
      </div>
      <div className={classes.row}>
        <div className="label">Công ty bảo hiểm</div>
        <div className="value">{data?.providerTitle || ""}</div>
      </div>
      <div className={classes.row}>
        <div className="label">Thời hạn bảo hiểm</div>
        <div className="value">12 tháng</div>
      </div>
      <div className={classes.row}>
        <div className="label">Điều kiện tham gia bảo hiểm</div>
        <div className="value">
          <div>
            {/* {`Từ ${
              data?.fromAge === 0 ? "dưới 1" : data?.fromAge || "...."
            } tuổi đến ${data?.toAge || "...."} tuổi`} */}
            Người Việt Nam hoặc người nước Ngoài sinh sống và làm việc ở Việt
            Nam trừ các trường hợp sau :
          </div>
          <div style={{ marginTop: 4 }}>
            - Không bị các bệnh tâm thần, phong
          </div>
          <div>
            - Không bị các bệnh ung thư (chỉ áp dụng cho Người được bảo hiểm
            (NĐBH) tham gia năm đầu tiên)
          </div>
          <div>
            - Không bị thương tật vĩnh viễn từ 50% trở lên (chỉ áp dụng cho NĐBH
            tham gia năm đầu tiên)
          </div>
          <div>
            - Không đang trong thời gian điều trị bệnh/thương tật (chỉ áp dụng
            cho NĐBH tham gia năm đầu tiên)
          </div>
          <div>
            Trường hợp tham gia bảo hiểm không đúng đối tượng và điều kiện quy
            định trên, Công ty bảo hiểm (CTBH) có quyền chấm dứt bảo hiểm và
            không chịu trách nhiệm với quyền lợi bảo hiểm đã đăng ký.
          </div>
        </div>
      </div>
      <div className={classes.row}>
        <div className="label">Tổng phí bảo hiểm/năm</div>
        <div className="text-bold">
          {formatMoney(detailEdit?.totalFee) || 0} VNĐ
        </div>
      </div>
      <div className={classes.row}>
        <div className="label">Chương trình bảo hiểm</div>
        <div className="text-bold">{data?.packagesTitle || ""}</div>
      </div>
      <div className={classes.row}>
        <div className="label">Phạm vi địa lý được bảo hiểm/Luật áp dụng</div>
        <div className="value">{data?.regions?.name || ""}</div>
      </div>
      <div className={classes.row}>
        <div className="label">Quyền lợi bảo hiểm/ Số tiền bảo hiểm</div>
        <div className="value">
          Theo Bảng tóm tắt Quyền lợi bảo hiểm dưới đây
        </div>
      </div>
      <div className={classes.title2}>BẢNG TÓM TẮT QUYỀN LỢI BẢO HIỂM</div>
      <div className={classes.unit}>Đơn vị: VNĐ</div>
      <div className={classes.tableHeader}>QUYỀN LỢI BẢO HIỂM CHÍNH</div>
      <div className={classes.benefitsTable}>
        {(data?.benefits?.primary || []).map((benefit, index) => (
          <div
            dangerouslySetInnerHTML={{ __html: benefit?.description || "" }}
          />
        ))}
      </div>
      {!_.isEmpty(detailEdit?.additional) && (
        <>
          <div className={classes.tableHeader} style={{ marginTop: "-2px" }}>
            QUYỀN LỢI BẢO HIỂM BỔ SUNG
          </div>
          <div className={classes.benefitsTable}>
            {(detailEdit?.additional || [])
              .filter((i) => i.checked === true)
              .map((benefit, index) => (
                <div
                  dangerouslySetInnerHTML={{
                    __html: benefit?.description || "",
                  }}
                />
              ))}
          </div>
        </>
      )}

      <div className={classes.waitingTable}>
        <div className="table-row">
          <div className="cell text-bold">Thời gian chờ</div>
          <div className="cell"></div>
        </div>
        {(data?.waitingTime || []).map((item, index) => (
          <div key={index} className="table-row">
            <div className="cell">{item?.label || ""}</div>
            <div className="cell">
              {item?.days ? `${item?.days} ngày` : "Không áp dụng"}
            </div>
          </div>
        ))}
      </div>

      <div className={classes.termsTable}>
        <div className="table-row">
          <div className="cell-3">Điều khoản bổ sung</div>
          <div className="cell-7">
            {(data?.extraTerms || [])
              .filter((i) => i.checked === true)
              .map((item, index) => (
                <div key={index}>
                  {index + 1}. {item?.content || ""}
                </div>
              ))}
          </div>
        </div>
        <div className="table-row">
          <div className="cell-3">Điều khoản loại trừ</div>
          <div className="cell-7">
            <div
              dangerouslySetInnerHTML={{ __html: data?.excludedTerms || "" }}
            />
          </div>
        </div>
        <div className="table-row">
          <div className="cell-3">Các định nghĩa</div>
          <div className="cell-7">
            <div dangerouslySetInnerHTML={{ __html: data?.definition || "" }} />
          </div>
        </div>
      </div>

      {/* <div className={classes.footer}>
        Hiệu lực bản chào có giá trị 15 ngày kể từ khi thông báo.
      </div> */}
    </div>
  );
});

export default ConvertPDF;

const useStyles = makeStyles(() => ({
  page: {
    backgroundColor: "white",
    margin: "32px",
    fontSize: 13,
    lineHeight: 1.2,
    // width: 824,
  },
  logo: {
    display: "flex",
    justifyContent: "center",
    "& .img": {
      marginBottom: 15,
      width: "12%",
    },
  },
  title: {
    textAlign: "center",
    fontWeight: "bold",
    fontSize: 22,
  },
  subTitle: {
    textAlign: "center",
    fontWeight: "bold",
    fontStyle: "italic",
    margin: "4px 0",
  },
  dateTime: {
    textAlign: "center",
    fontStyle: "italic",
    marginBottom: 25,
  },
  row: {
    display: "flex",
    flexDirection: "row",
    marginBottom: 4,
    "& .label": {
      fontWeight: "bold",
      width: "25%",
    },
    "& .value": {
      marginLeft: 5,
      width: "75%",
    },
    "& .text-bold": {
      marginLeft: 5,
      width: "75%",
      fontWeight: "bold",
    },
  },

  title2: {
    textAlign: "center",
    fontWeight: "bold",
    fontSize: 18,
  },
  unit: {
    textAlign: "right",
    fontStyle: "italic",
    marginBottom: 6,
  },

  tableHeader: {
    fontWeight: "bold",
    padding: 5,
    border: "1px solid #000",
    backgroundColor: "#D9D9D9",
    width: "calc(100% - 12px)",
  },

  benefitsTable: {
    "& table": {
      maxWidth: "100%",
      width: "100% !important",
      margin: "0 !important",
    },
    "& td": { padding: 5 },
  },

  waitingTable: {
    marginTop: 25,
    "& .table-row": {
      display: "flex",
      flexDirection: "row",
    },
    "& .cell": {
      width: "50%",
      padding: 4,
      border: "0.5px solid #000",
    },
    "& .text-bold": {
      fontWeight: "bold",
    },
  },

  termsTable: {
    marginTop: 25,
    "& .table-row": {
      display: "flex",
      flexDirection: "row",
    },
    "& .cell-3": {
      width: "30%",
      padding: 4,
      border: "0.5px solid #000",
      fontWeight: "bold",
    },
    "& .cell-7": {
      width: "70%",
      padding: 4,
      border: "0.5px solid #000",
    },
  },
  footer: {
    marginTop: 10,
    fontStyle: "italic",
  },
}));
