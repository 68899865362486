import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  container: {
    margin: "20px 5%",
    [theme.breakpoints.up("xl")]: {
      margin: "20px 10%",
    },
    [theme.breakpoints.down("sm")]: {
      margin: "20px 10px",
      fontSize: 14,
    },

    "& .coming-logo": {
      [theme.breakpoints.down("sm")]: {
        width: "90%",
      },
    },
  },
}));
