import ClearIcon from "@mui/icons-material/Clear";
import CloseIcon from "@mui/icons-material/Close";

import {
  Button,
  Dialog,
  Divider,
  IconButton,
  InputAdornment,
  Slide,
  TextField,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function InfoDialog({ dataInfo, dispatch }) {
  const classes = useStyles();

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={!!dataInfo?.open}
      TransitionComponent={Transition}
      keepMounted
      className={classes.info}
    >
      <div className="content">
        <div className="row header">
          <div></div>
          <div className="mx-50">
            Vui lòng để lại số điện thoại để xem chi tiết
          </div>
          <CloseIcon onClick={() => dispatch("closeInfo")} />
        </div>
        <Divider />

        <div>
          <div className="label">Họ và tên</div>
          <TextField
            fullWidth
            size="small"
            variant="outlined"
            value={dataInfo?.name || ""}
            placeholder="Nhập thông tin"
            className="capitalize"
            onChange={(e) =>
              dispatch({
                type: "onChangeInfo",
                name: "name",
                value: e.target.value,
              })
            }
            InputProps={{
              endAdornment: dataInfo?.name ? (
                <ClearInputIcon name={"name"} dispatch={dispatch} />
              ) : null,
            }}
          />
        </div>
        <div>
          <div className="label">
            Số điện thoại <span className="iconRed">*</span>
          </div>
          <TextField
            fullWidth
            size="small"
            type="tel"
            variant="outlined"
            value={dataInfo?.phone || ""}
            placeholder="Nhập thông tin"
            inputProps={{ maxLength: 10 }}
            onChange={(e) => {
              if (
                !/^\d*$/.test(e?.target?.value) &&
                e?.nativeEvent?.data !== null
              )
                return;

              dispatch({
                type: "onChangeInfo",
                name: "phone",
                value: e.target.value,
              });
            }}
            InputProps={{
              endAdornment: dataInfo?.phone ? (
                <ClearInputIcon name={"phone"} dispatch={dispatch} />
              ) : null,
            }}
          />
          {!!dataInfo?.error && (
            <div className="err">Số điện thoại không đúng định dạng</div>
          )}
        </div>

        <div className="action">
          <Button
            className="btn-outlined"
            onClick={() => dispatch("closeInfo")}
          >
            Quay lại
          </Button>
          <Button
            className="btn"
            disabled={!dataInfo?.phone}
            onClick={() => dispatch("sendInfoToView")}
          >
            Tiếp tục
          </Button>
        </div>
      </div>
    </Dialog>
  );
}

export default InfoDialog;

const ClearInputIcon = ({ name, dispatch }) => (
  <InputAdornment position="end">
    <IconButton
      size="small"
      onClick={() => dispatch({ type: "onChangeInfo", name, value: null })}
    >
      <ClearIcon style={{ fontSize: 14 }} />
    </IconButton>
  </InputAdornment>
);

const useStyles = makeStyles((theme) => ({
  info: {
    fontSize: 16,
    [theme.breakpoints.down("sm")]: {
      fontSize: 14,
    },

    "& .MuiDialog-paper": {
      maxWidth: "450px",
      borderRadius: 16,
      margin: 10,
      [theme.breakpoints.down("sm")]: {
        minWidth: "95vw",
      },
    },
    "& .content": {
      display: "grid",
      gap: 15,
      padding: 25,
      [theme.breakpoints.down("sm")]: {
        padding: "20px 15px",
      },
    },
    "& .row": {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
    },
    "& .header": {
      color: "#1A427D",
      fontWeight: 600,
      fontSize: 20,
      textAlign: "center",
      [theme.breakpoints.down("sm")]: {
        fontSize: 16,
      },
    },
    "& .mx-50": { margin: "0 50px" },

    "& .label": {
      fontWeight: 500,
      marginBottom: "6px",
      color: "#333",
    },
    "& .iconRed": {
      color: "red",
      fontWeight: 500,
    },
    "& .capitalize": {
      textTransform: "capitalize",
      "& .MuiOutlinedInput-input": {
        textTransform: "capitalize",
      },
    },

    "& .action": {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      marginTop: 10,
    },
    "& .btn": {
      width: "50%",
      padding: "6px 12px",
      borderRadius: 4,
      textTransform: "none",
      fontSize: 16,
      fontWeight: 500,
      color: "#fff",
      background: "#1A427D",
      border: "1px solid #1A427D",
      "&:hover": {
        color: "#fff",
        background: "#1A427D",
      },
    },
    "& .btn-outlined": {
      width: "50%",
      marginRight: 20,
      borderRadius: 4,
      border: "1px solid #1A427D",
      background: "#fff",
      color: "#1A427D",
      textTransform: "none",
      fontSize: 16,
      boxShadow: "none",
    },

    "& .Mui-disabled": {
      background: "#f1f1f1",
      border: "1px solid #f1f1f1",
    },

    "& .err": {
      color: "red",
      fontSize: 13,
      marginTop: "4px",
    },
    "& .MuiOutlinedInput-input": {
      [theme.breakpoints.down("sm")]: {
        fontSize: 14,
      },
    },
  },
}));
