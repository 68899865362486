import { Button, Dialog, DialogContent } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";

import errIcon from "../../assets/icons/err-noti.svg";
import warningIcon from "../../assets/icons/warning-noti.svg";

function WarningOrder({ data, dispatch }) {
  const classes = useStyles();

  return (
    <Dialog open={!!data?.openWarning} keepMounted className={classes.dialog}>
      <DialogContent className={classes.content}>
        {data?.notiType === "warning" && (
          <>
            <img src={warningIcon} alt="noti-icon" />
            <div className="noti-text">
              {`Rất tiếc, ${
                data?.insuredName || ""
              } không được tham gia quyền lợi ${data?.missingBenefitTitle}`}
            </div>
            <div className="confirm">
              Bạn có muốn bỏ quyền lợi này ra không?
            </div>
            <div className="action">
              <Button
                className="btn-outlined"
                onClick={() => dispatch("closeWarning")}
              >
                Quay lại
              </Button>
              <Button
                className="btn"
                onClick={() => dispatch("acceptRemovalOfBenefit")}
              >
                Đồng ý
              </Button>
            </div>
          </>
        )}
        {data?.notiType === "error" && (
          <>
            <img src={errIcon} alt="noti-icon" />
            <div className="noti-text">
              {`Rất tiếc, độ tuổi của 
                ${
                  data?.insuredName || ""
                } không được tham gia gói bảo hiểm này.`}
            </div>
            <div className="action">
              <Button
                className="btn-outlined"
                onClick={() => dispatch("closeWarning")}
              >
                Quay lại
              </Button>
              <Button className="btn" onClick={() => dispatch("goBackHome")}>
                Trở về trang chủ
              </Button>
            </div>
          </>
        )}
      </DialogContent>
    </Dialog>
  );
}

export default WarningOrder;

const useStyles = makeStyles(() => ({
  dialog: {
    "& .MuiPaper-root.MuiPaper-elevation": {
      borderRadius: 5,
      width: "90%",
      maxWidth: "400px",
      margin: 0,
    },
  },

  content: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    "& .noti-text": {
      marginTop: 15,
    },
    "& .confirm": {
      fontWeight: "bold",
      marginTop: 15,
    },

    "& .action": {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      marginTop: 20,
    },
    "& .btn": {
      padding: "6px 15px",
      borderRadius: 6,
      textTransform: "none",
      fontSize: 14,
      fontWeight: 500,
      color: "#fff",
      background: "#0B7CE4",
      border: "1px solid #0B7CE4",
      "&:hover": {
        color: "#fff",
        background: "#0B7CE4",
      },
    },
    "& .btn-outlined": {
      padding: "6px 15px",
      marginRight: 15,
      borderRadius: 6,
      border: "1px solid #3961A0",
      background: "#fff",
      color: "#3961A0",
      textTransform: "none",
      fontSize: 14,
      boxShadow: "none",
    },
  },
}));
