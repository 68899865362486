import { CircularProgress, Dialog, DialogContent, Slide } from "@mui/material";
import { makeStyles } from "@mui/styles";
import * as React from "react";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function DialogNoti({ open }) {
  const classes = makeStyle();

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      className={classes.dialog}
    >
      <DialogContent className={classes.content}>
        <CircularProgress size={24} sx={{ m: 1, color: "#284078" }} />
        <div className="title">Đang tạo đơn</div>
        <div className="action"> Quá trình này sẽ mất khoảng vài phút...</div>
      </DialogContent>
    </Dialog>
  );
}

const makeStyle = makeStyles(() => ({
  dialog: {
    "& .MuiPaper-root.MuiPaper-elevation": {
      borderRadius: 5,
      width: "80%",
      maxWidth: "400px",
      margin: 0,
    },
  },

  content: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    "& .title": {
      textAlign: "center",
      color: "#1A427D",
      margin: 10,
      fontWeight: 600,
      fontSize: 18,
    },
    "& .action": {
      textAlign: "center",
      fontSize: "13px",
      color: "#FB7800",
      fontStyle: "italic",
    },
  },
}));
