import { makeStyles } from "@mui/styles";
import { motion } from "framer-motion";
import React from "react";
import { Autoplay, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

import banner1 from "../assets/images/banner-1.png";
import banner1a from "../assets/images/banner-1a.png";
import banner2 from "../assets/images/banner-2.png";
import banner2a from "../assets/images/banner-2a.png";
import banner3 from "../assets/images/banner-3.jpg";
import banner3a from "../assets/images/banner-3a.jpg";

import "swiper/css";
import "swiper/css/pagination";

const banners = [
  {
    imgMobile: banner3a,
    img: banner3,
    link: "https://core.globalcare.vn/app/gsale/download",
  },
  {
    imgMobile: banner1a,
    img: banner1,
    link: "https://core.globalcare.vn/app/gsale/download",
  },
  {
    imgMobile: banner2a,
    img: banner2,
    link: "https://core.globalcare.vn/app/gsale/download",
  },
];

const animateRight = {
  initial: { x: 50, opacity: 0 },
  animate: { x: 0, opacity: 1 },
  exit: { x: -50, opacity: 0 },
  transition: { duration: 0.5 },
};

function Banner({ isMobile }) {
  const classes = useStyles();

  return (
    <div>
      <motion.div key="swiper-1" {...animateRight}>
        <div className={classes.swiper}>
          <Swiper
            spaceBetween={30}
            centeredSlides={true}
            autoplay={{
              delay: 3000,
              disableOnInteraction: false,
            }}
            pagination={{
              dynamicBullets: true,
              clickable: true,
            }}
            modules={[Autoplay, Pagination]}
            className="mySwiper"
          >
            {banners.map((banner, index) => (
              <SwiperSlide key={index}>
                {/* <a
                  href={banner.link}
                  target="_blank"
                  style={{ color: "#fff", textDecoration: "none" }}
                > */}
                <img
                  src={isMobile ? banner.imgMobile : banner.img}
                  alt={`banner-${index}`}
                  className="banner"
                ></img>
                {/* </a> */}
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </motion.div>
    </div>
  );
}

export default Banner;

const useStyles = makeStyles((theme) => ({
  swiper: {
    "& .banner": {
      width: "100%",
      cursor: "pointer",
      borderRadius: 24,
      [theme.breakpoints.down("sm")]: {
        margin: 0,
      },
    },
    "& .swiper-pagination-bullet-active": {
      background: "#fff",
    },
  },
}));
