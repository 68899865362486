import ClearIcon from "@mui/icons-material/Clear";
import CloseIcon from "@mui/icons-material/Close";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";

import {
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  Divider,
  FormControlLabel,
  IconButton,
  InputAdornment,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Slide,
  Step,
  StepConnector,
  StepLabel,
  Stepper,
  TextField,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { withStyles } from "@mui/styles";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import _ from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";

import {
  BpCheckedIcon,
  BpIcon,
  genders,
  relationship,
} from "../../extra/hard-data";
import { createEventGtag, formatMoney, getParamUrl } from "../../extra/utils";
import { useStyles } from "../../styles/bhskStyles";
import Signature from "../Signature";
import DialogNoti from "./NotiDialog";
import WarningOrder from "./WarningOrder";

import checkedErr from "../../assets/icons/checked-err.svg";
import step1 from "../../assets/icons/step1-active.svg";
import step2 from "../../assets/icons/step2-active.svg";
import step2In from "../../assets/icons/step2-inactive.svg";
import step3 from "../../assets/icons/step3-active.svg";
import step3In from "../../assets/icons/step3-inactive.svg";
import warning from "../../assets/icons/warning.svg";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const steps = [
  { label: "Thông tin người được BH", icon: step1 },
  { label: "Câu hỏi thẩm định", icon: step2, inactive: step2In },
  { label: "Thông tin thanh toán", icon: step3, inactive: step3In },
];

const infoInsured = (data) => [
  { label: "Họ và tên", value: data?.insuredName, capitalize: true },
  {
    label: "Giới tính",
    value: genders.find((i) => i.id === data?.insuredGender)?.label,
  },
  {
    label: "Ngày sinh",
    value: moment(data?.insuredDob?.toDate()).format("DD/MM/YYYY"),
  },

  {
    label: "CCCD/ CMND/ Hộ chiếu/ Số định danh cá nhân",
    value: data?.insuredPrivateId,
  },
  { label: "Email", value: data?.insuredEmail },
  { label: "Số điện thoại", value: data?.insuredPhone },
  { label: "Địa chỉ", value: data?.insuredAddress },
  {
    label: "Thời hạn bảo hiểm",
    value: `${moment(data?.beginDate?.toDate()).format(
      "DD/MM/YYYY"
    )} - ${moment(data?.endDate?.toDate()).format("DD/MM/YYYY")}`,
  },
  { label: "Mã giới thiệu", value: data?.referalCode },
];

const infoBuyer = (data) => [
  { label: "Họ và tên", value: data?.buyerName, capitalize: true },
  {
    label: "Giới tính",
    value: genders.find((i) => i.id === data?.buyerGender)?.label,
  },
  {
    label: "Ngày sinh",
    value: moment(data?.buyerDob?.toDate()).format("DD/MM/YYYY"),
  },

  {
    label: "CCCD/ CMND/ Hộ chiếu/ Số định danh cá nhân",
    value: data?.buyerPrivateId,
  },
  { label: "Email", value: data?.buyerEmail },
  { label: "Số điện thoại", value: data?.buyerPhone },
  { label: "Địa chỉ", value: data?.buyerAddress },
  {
    label: "Mối quan hệ với người được bảo hiểm",
    value: relationship.find((i) => i.id === data?.relationshipWithBuyerId)
      ?.label,
  },
];

const infoInsurance = (data) => [
  { label: "Công ty bảo hiểm", value: data?.providerTitle },
  { label: "Tên bảo hiểm", value: data?.productTitle },
  { label: "Gói bảo hiểm", value: data?.packagesTitle },
];

const QontoConnector = withStyles({})(StepConnector);

function OrderDialog({ state, dispatch, isMobile }) {
  const {
    detailEdit,
    detailData,
    openOrder,
    currentStep,
    dataStep1,
    clear,
    dataStep2,
    dataStep3,
    errors,
    loadDetail,
  } = state;
  const classes = useStyles();
  const [isFirstRender, setIsFirstRender] = useState(true);

  useEffect(() => {
    if (isFirstRender) {
      setIsFirstRender(false);
    }
    if (detailEdit?.canRecallDetail) {
      dispatch("recallGetDetailByUid");
    }
  }, [dataStep1?.insuredGender, dataStep1?.insuredDob]);

  useEffect(() => {
    if (!isFirstRender && detailEdit?.canRecallFee) {
      dispatch("recallGetFee");
    }
  }, [detailEdit?.canRecallFee]);

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={openOrder}
      TransitionComponent={Transition}
      keepMounted
      className={classes.orderDialog}
    >
      <DialogNoti open={!!dataStep3?.openNoti} />
      <WarningOrder data={dataStep1} dispatch={dispatch} />
      <div className="close-icon">
        <CloseIcon
          onClick={() => {
            dispatch("closeOrderDialog");
            createEventGtag("health_home_order_close_btn");
          }}
        />
      </div>
      <Stepper
        currentStep={currentStep}
        alternativeLabel
        connector={<QontoConnector />}
      >
        {steps.map((step, index) => (
          <Step key={index} className={classes.step}>
            <StepLabel
              StepIconComponent={() => (
                <div className="box-step">
                  <img
                    src={currentStep < index + 1 ? step.inactive : step.icon}
                    alt={`${step.icon}`}
                    className={currentStep < index + 1 ? "await" : "active"}
                  />
                  <div
                    className={
                      currentStep < index + 1 ? "label await" : "label"
                    }
                  >
                    {step?.label}
                  </div>
                </div>
              )}
            ></StepLabel>
          </Step>
        ))}
      </Stepper>
      <div className="titleStep mt-20">{steps[currentStep - 1]?.label}</div>
      <Divider className="my-10" />
      {currentStep === 1 && (
        <>
          <Grid container spacing={2} className="px-0 my-10">
            <Grid xs={12} sm={12} md={6}>
              <TextInput
                label="Họ và tên"
                value={dataStep1?.insuredName || ""}
                className="capitalize"
                onChange={(e) =>
                  // if(!nameValidate(e?.nativeEvent?.data) &&
                  // e?.nativeEvent?.data !== null) return;
                  dispatch({
                    type: "onChangeStep1",
                    name: "insuredName",
                    value: e.target.value,
                  })
                }
                onClear={() =>
                  dispatch({
                    type: "onChangeStep1",
                    name: "insuredName",
                    value: null,
                  })
                }
              />
              {errors.includes("insuredName") && (
                <div className="err">Vui lòng nhập thông tin</div>
              )}
            </Grid>
            <Grid xs={12} sm={12} md={6}>
              <div className="textInput">
                <div className="label">
                  Giới tính <span className="iconRed">*</span>
                </div>
                <Select
                  size="small"
                  fullWidth
                  value={dataStep1?.insuredGender || 3}
                  onChange={(e) =>
                    dispatch({
                      type: "onChangeStep1",
                      name: "insuredGender",
                      value: e.target.value,
                      canRecallDetail: true,
                    })
                  }
                >
                  <MenuItem disabled value={3}>
                    <em style={{ fontStyle: "normal", color: "#969696" }}>
                      Chọn
                    </em>
                  </MenuItem>
                  {genders.map((item, index) => (
                    <MenuItem key={index} value={item.id}>
                      {item.label}
                    </MenuItem>
                  ))}
                </Select>
              </div>
              {errors.includes("insuredGender") && (
                <div className="err">Vui lòng nhập thông tin</div>
              )}
            </Grid>
            <Grid xs={12} sm={12} md={6}>
              <div className="textInput">
                <div className="label">
                  Ngày sinh <span className="iconRed">*</span>
                </div>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    disableFuture
                    format="DD/MM/YYYY"
                    value={dataStep1?.insuredDob || null}
                    onChange={(newValue) =>
                      dispatch({
                        type: "onChangeStep1",
                        name: "insuredDob",
                        value: newValue,
                        canRecallDetail: true,
                      })
                    }
                  />
                </LocalizationProvider>
              </div>
              {errors.includes("insuredDob") && (
                <div className="err">Vui lòng nhập thông tin</div>
              )}
              {errors.includes("dobValid") && (
                <div className="err">Nhập ngày sinh tối đa 100 tuổi</div>
              )}
              {errors.includes("dobFuture") && (
                <div className="err">Nhập ngày sinh không hợp lệ</div>
              )}
            </Grid>
            <Grid xs={12} sm={12} md={6}>
              <div className="textInput">
                <div className="label">
                  CCCD/ CMND/ Hộ chiếu/ Số định danh cá nhân{" "}
                  <span className="iconRed">*</span>
                </div>
                <TextField
                  fullWidth
                  size="small"
                  variant="outlined"
                  value={dataStep1?.insuredPrivateId || ""}
                  placeholder="Nhập thông tin"
                  inputProps={{ maxLength: 12 }}
                  onChange={(e) =>
                    dispatch({
                      type: "onChangeStep1",
                      name: "insuredPrivateId",
                      value: e.target.value,
                    })
                  }
                  InputProps={{
                    endAdornment: dataStep1?.insuredPrivateId ? (
                      <ClearInputIcon
                        name={"insuredPrivateId"}
                        dispatch={dispatch}
                      />
                    ) : null,
                  }}
                />
              </div>
              {errors.includes("insuredPrivateId") && (
                <div className="err">Vui lòng nhập thông tin</div>
              )}
              {errors.includes("insuredPrivateIdValid") && (
                <div className="err">
                  CCCD/ CMND/ Hộ chiếu/ Số định danh cá nhân không đúng định
                  dạng
                </div>
              )}
              {!!dataStep1?.under18 && (
                <div className="err">
                  Dưới 18 tuổi vui lòng nhập số định danh cá nhân
                </div>
              )}
            </Grid>
            <Grid xs={12} sm={12} md={6}>
              <TextInput
                label="Email"
                value={dataStep1?.insuredEmail || ""}
                onChange={(e) =>
                  dispatch({
                    type: "onChangeStep1",
                    name: "insuredEmail",
                    value: e.target.value,
                  })
                }
                onClear={() =>
                  dispatch({
                    type: "onChangeStep1",
                    name: "insuredEmail",
                    value: null,
                  })
                }
              />
              {errors.includes("insuredEmail") && (
                <div className="err">Vui lòng nhập thông tin</div>
              )}
              {errors.includes("insuredEmailValid") && (
                <div className="err">Email không đúng định dạng</div>
              )}
            </Grid>
            <Grid xs={12} sm={12} md={6}>
              <div className="textInput">
                <div className="label">
                  Số điện thoại <span className="iconRed">*</span>
                </div>
                <TextField
                  fullWidth
                  type="tel"
                  size="small"
                  variant="outlined"
                  value={dataStep1?.insuredPhone || ""}
                  placeholder="Nhập thông tin"
                  inputProps={{ maxLength: 10 }}
                  onChange={(e) => {
                    if (
                      !/^\d*$/.test(e?.target?.value) &&
                      e?.nativeEvent?.data !== null
                    )
                      return;
                    dispatch({
                      type: "onChangeStep1",
                      name: "insuredPhone",
                      value: e.target.value,
                    });
                  }}
                  InputProps={{
                    endAdornment: dataStep1?.insuredPhone ? (
                      <ClearInputIcon
                        name={"insuredPhone"}
                        dispatch={dispatch}
                      />
                    ) : null,
                  }}
                />
              </div>
              {errors.includes("insuredPhone") && (
                <div className="err">Vui lòng nhập thông tin</div>
              )}
              {errors.includes("insuredPhoneValid") && (
                <div className="err">Số điện thoại không đúng định dạng</div>
              )}
            </Grid>
            <Grid xs={12} sm={12} md={6}>
              <TextInput
                label="Địa chỉ"
                value={dataStep1?.insuredAddress || ""}
                onChange={(e) =>
                  dispatch({
                    type: "onChangeStep1",
                    name: "insuredAddress",
                    value: e.target.value,
                  })
                }
                onClear={() =>
                  dispatch({
                    type: "onChangeStep1",
                    name: "insuredAddress",
                    value: null,
                  })
                }
              />
              {errors.includes("insuredAddress") && (
                <div className="err">Vui lòng nhập thông tin</div>
              )}
            </Grid>
            <Grid xs={12} sm={12} md={6}>
              <div className="textInput">
                <div className="label">
                  Ngày hiệu lực <span className="iconRed">*</span>
                </div>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    format="DD/MM/YYYY"
                    value={dataStep1?.beginDate || null}
                    minDate={
                      detailData?.providerId === 16
                        ? dayjs().add(2, "day")
                        : dayjs().add(1, "day")
                    }
                    onChange={(newValue) =>
                      dispatch({
                        type: "onChangeStep1",
                        name: "beginDate",
                        value: newValue,
                      })
                    }
                  />
                </LocalizationProvider>
              </div>
              {errors.includes("beginDate") && (
                <div className="err">Vui lòng nhập thông tin</div>
              )}
              {errors.includes("dateValid") && (
                <div className="err">Ngày hiệu lực không hợp lệ</div>
              )}
            </Grid>
            {!isMobile && (
              <Grid xs={12} sm={12} md={6}>
                <div className="textInput">
                  <div className="label">Mã giới thiệu</div>
                  <TextField
                    fullWidth
                    size="small"
                    variant="outlined"
                    value={dataStep1?.referalCode || ""}
                    placeholder="Nhập thông tin"
                    disabled={!!getParamUrl("referalCode")}
                    onChange={(e) =>
                      dispatch({
                        type: "onChangeStep1",
                        name: "referalCode",
                        value: e.target.value,
                      })
                    }
                  />
                </div>
              </Grid>
            )}
            <Grid xs={12} sm={12} md={6}>
              <div className="textInput">
                <div className="label">
                  Ngày kết thúc <span className="iconRed">*</span>
                </div>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    disabled
                    format="DD/MM/YYYY"
                    value={dataStep1?.endDate || null}
                  />
                </LocalizationProvider>
              </div>
            </Grid>
            {isMobile && (
              <Grid xs={12} sm={12} md={6}>
                <div className="textInput">
                  <div className="label">Mã giới thiệu</div>
                  <TextField
                    fullWidth
                    size="small"
                    variant="outlined"
                    value={dataStep1?.referalCode || ""}
                    placeholder="Nhập thông tin"
                    disabled={!!getParamUrl("referalCode")}
                    onChange={(e) =>
                      dispatch({
                        type: "onChangeStep1",
                        name: "referalCode",
                        value: e.target.value,
                      })
                    }
                  />
                </div>
              </Grid>
            )}
            {!!dataStep1.under18 && (
              <Grid xs={12} sm={12} md={6}>
                <div className="textInput">
                  <div className="label">
                    Mối quan hệ với chủ hợp đồng{" "}
                    <span className="iconRed">*</span>
                  </div>
                  <TextField
                    select
                    size="small"
                    fullWidth
                    value={dataStep1?.relationshipWithBuyerId}
                    onChange={(e) =>
                      dispatch({
                        type: "onChangeStep1",
                        name: "relationshipWithBuyerId",
                        value: e.target.value,
                      })
                    }
                  >
                    {relationship.map((item, index) => (
                      <MenuItem key={index} value={item.id}>
                        {item.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </div>
                {errors.includes("relationshipWithBuyerId") && (
                  <div className="err">Vui lòng nhập thông tin</div>
                )}
              </Grid>
            )}
          </Grid>
          {!!dataStep1.under18 && (
            <>
              <Divider className="my-10" />
              <div className="titleStep my-10">THÔNG TIN CHỦ HỢP ĐỒNG</div>
              <div className="bg-color">
                <img src={warning} alt="warning" style={{ marginRight: 5 }} />
                <div>
                  Người được bảo hiểm dưới 18 tuổi vui lòng bổ sung thêm thông
                  tin chủ hợp đồng
                </div>
              </div>
              <Grid container spacing={2} className="my-10">
                <Grid xs={12} sm={12} md={6}>
                  <TextInput
                    label="Họ và tên"
                    value={dataStep1?.buyerName || ""}
                    className="capitalize"
                    onChange={(e) =>
                      dispatch({
                        type: "onChangeStep1",
                        name: "buyerName",
                        value: e.target.value,
                      })
                    }
                    onClear={() =>
                      dispatch({
                        type: "onChangeStep1",
                        name: "buyerName",
                        value: null,
                      })
                    }
                  />
                  {errors.includes("buyerName") && (
                    <div className="err">Vui lòng nhập thông tin</div>
                  )}
                </Grid>
                <Grid xs={12} sm={12} md={6}>
                  <div className="textInput">
                    <div className="label">
                      Giới tính <span className="iconRed">*</span>
                    </div>
                    <Select
                      size="small"
                      fullWidth
                      value={dataStep1?.buyerGender || 3}
                      onChange={(e) =>
                        dispatch({
                          type: "onChangeStep1",
                          name: "buyerGender",
                          value: e.target.value,
                        })
                      }
                    >
                      <MenuItem disabled value={3}>
                        <em style={{ fontStyle: "normal", color: "#969696" }}>
                          Chọn
                        </em>
                      </MenuItem>
                      {genders.map((item, index) => (
                        <MenuItem key={index} value={item.id}>
                          {item.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </div>
                  {errors.includes("buyerGender") && (
                    <div className="err">Vui lòng nhập thông tin</div>
                  )}
                </Grid>
                <Grid xs={12} sm={12} md={6}>
                  <div className="textInput">
                    <div className="label">
                      Ngày sinh <span className="iconRed">*</span>
                    </div>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        disableFuture
                        format="DD/MM/YYYY"
                        value={dataStep1?.buyerDob || null}
                        onChange={(newValue) =>
                          dispatch({
                            type: "onChangeStep1",
                            name: "buyerDob",
                            value: newValue,
                          })
                        }
                      />
                    </LocalizationProvider>
                  </div>
                  {errors.includes("buyerDob") && (
                    <div className="err">Vui lòng nhập thông tin</div>
                  )}
                  {errors.includes("buyerBirthdayValid") && (
                    <div className="err">Nhập ngày sinh tối đa 100 tuổi</div>
                  )}
                  {errors.includes("buyerDobValid") && (
                    <div className="err">
                      Chủ hợp đồng bắt buộc phải trên 18 tuổi
                    </div>
                  )}
                  {errors.includes("buyerDobFuture") && (
                    <div className="err">Nhập ngày sinh không hợp lệ</div>
                  )}
                </Grid>
                <Grid xs={12} sm={12} md={6}>
                  <div className="textInput">
                    <div className="label">
                      CCCD/ CMND/ Hộ chiếu/ Số định danh cá nhân{" "}
                      <span className="iconRed">*</span>
                    </div>
                    <TextField
                      fullWidth
                      size="small"
                      variant="outlined"
                      value={dataStep1?.buyerPrivateId || ""}
                      placeholder="Nhập thông tin"
                      inputProps={{ maxLength: 12 }}
                      onChange={(e) =>
                        dispatch({
                          type: "onChangeStep1",
                          name: "buyerPrivateId",
                          value: e.target.value,
                        })
                      }
                      InputProps={{
                        endAdornment: dataStep1?.buyerPrivateId ? (
                          <ClearInputIcon
                            name={"buyerPrivateId"}
                            dispatch={dispatch}
                          />
                        ) : null,
                      }}
                    />
                  </div>
                  {errors.includes("buyerPrivateId") && (
                    <div className="err">Vui lòng nhập thông tin</div>
                  )}
                  {errors.includes("buyerPrivateIdValid") && (
                    <div className="err">
                      CCCD/ CMND/ Hộ chiếu/ Số định danh cá nhân không đúng định
                      dạng
                    </div>
                  )}
                </Grid>
                <Grid xs={12} sm={12} md={6}>
                  <TextInput
                    label="Email"
                    value={dataStep1?.buyerEmail || ""}
                    onChange={(e) =>
                      dispatch({
                        type: "onChangeStep1",
                        name: "buyerEmail",
                        value: e.target.value,
                      })
                    }
                    onClear={() =>
                      dispatch({
                        type: "onChangeStep1",
                        name: "buyerEmail",
                        value: null,
                      })
                    }
                  />
                  {errors.includes("buyerEmail") && (
                    <div className="err">Vui lòng nhập thông tin</div>
                  )}
                  {errors.includes("buyerEmailValid") && (
                    <div className="err">Email không đúng định dạng</div>
                  )}
                </Grid>
                <Grid xs={12} sm={12} md={6}>
                  <div className="textInput">
                    <div className="label">
                      Số điện thoại <span className="iconRed">*</span>
                    </div>
                    <TextField
                      fullWidth
                      type="tel"
                      size="small"
                      variant="outlined"
                      value={dataStep1?.buyerPhone || ""}
                      placeholder="Nhập thông tin"
                      inputProps={{ maxLength: 10 }}
                      onChange={(e) => {
                        if (
                          !/^\d*$/.test(e?.target?.value) &&
                          e?.nativeEvent?.data !== null
                        )
                          return;
                        dispatch({
                          type: "onChangeStep1",
                          name: "buyerPhone",
                          value: e.target.value,
                        });
                      }}
                      InputProps={{
                        endAdornment: dataStep1?.buyerPhone ? (
                          <ClearInputIcon
                            name={"buyerPhone"}
                            dispatch={dispatch}
                          />
                        ) : null,
                      }}
                    />
                  </div>
                  {errors.includes("buyerPhone") && (
                    <div className="err">Vui lòng nhập thông tin</div>
                  )}
                  {errors.includes("buyerPhoneValid") && (
                    <div className="err">
                      Số điện thoại không đúng định dạng
                    </div>
                  )}
                </Grid>
                <Grid xs={12} sm={12} md={6}>
                  <TextInput
                    label="Địa chỉ"
                    value={dataStep1?.buyerAddress || ""}
                    onChange={(e) =>
                      dispatch({
                        type: "onChangeStep1",
                        name: "buyerAddress",
                        value: e.target.value,
                      })
                    }
                    onClear={() =>
                      dispatch({
                        type: "onChangeStep1",
                        name: "buyerAddress",
                        value: null,
                      })
                    }
                  />
                  {errors.includes("buyerAddress") && (
                    <div className="err">Vui lòng nhập thông tin</div>
                  )}
                </Grid>
              </Grid>
            </>
          )}

          <Divider />
          <div
            className="between-row"
            style={{ margin: isMobile ? "15px 0px 8px" : "15px 8px 5px" }}
          >
            <div className="lg-bold color1">Tổng tiền thanh toán</div>
            <div className="lg-bold fee">
              {detailEdit.loadingFee || loadDetail ? (
                <CircularProgress
                  size={15}
                  color="inherit"
                  style={{ marginRight: 5 }}
                />
              ) : (
                <span>{formatMoney(detailEdit?.totalFee) || 0} </span>
              )}
              VNĐ
            </div>
          </div>
        </>
      )}

      {currentStep === 2 && (
        <div className="wrapper">
          <div className="bg-color">
            <img src={warning} alt="warning" style={{ marginRight: 5 }} />
            <div>
              Đây là phần câu hỏi bắt buộc, khách hàng cần trả lời tất cả câu
              hỏi phía dưới.
            </div>
          </div>
          {errors.includes("notEnoughCondition") ? (
            <div className="bg-red">
              Rất tiếc, khách hàng{" "}
              <span className="capitalize" style={{ fontWeight: 600 }}>
                {dataStep1?.insuredName || ""}{" "}
              </span>{" "}
              chưa đủ điều kiện tham gia gói bảo hiểm này!
            </div>
          ) : (
            <div className="my-15">
              Người được bảo hiểm{" "}
              <span className="text-color capitalize">
                {dataStep1?.insuredName || ""}{" "}
              </span>
              có bị mắc một trong số các bệnh dưới đây không?
            </div>
          )}
          <Grid container spacing={2}>
            {(dataStep2?.assessmentQuestion || []).map((q, index) => (
              <>
                <Grid xs={12} sm={12} md={9} alignContent={"center"}>
                  <div style={{ textAlign: "justify" }}>{`${index + 1}. ${
                    q.content
                  }`}</div>
                </Grid>
                <Grid xs={12} sm={12} md={3}>
                  <RadioGroup
                    row
                    value={q?.answer}
                    onChange={(e) =>
                      dispatch({
                        type: "onChangeStep2",
                        index,
                        value: e.target.value,
                      })
                    }
                    className={errors.includes(`ques${index}`) ? "error" : ""}
                    style={{ justifyContent: !isMobile ? "flex-end" : "" }}
                  >
                    <FormControlLabel
                      value={false}
                      control={<Radio />}
                      label="Không"
                    />
                    <FormControlLabel
                      value={true}
                      control={
                        <Radio
                          checkedIcon={
                            errors.includes(`notEnoughCondition`) &&
                            !q?.required ? (
                              <img src={checkedErr} alt="checkedErr" />
                            ) : (
                              <RadioButtonCheckedIcon />
                            )
                          }
                        />
                      }
                      label="Có"
                    />
                  </RadioGroup>
                </Grid>
              </>
            ))}
          </Grid>
          {errors.some((err) => err.startsWith("ques")) && (
            <div className="err">Vui lòng trả lời tất cả câu hỏi</div>
          )}
        </div>
      )}

      {currentStep === 3 && (
        <div>
          <div className="text-color fz-16">Thông tin người được bảo hiểm</div>
          <Grid container spacing={2} className="my-10">
            {infoInsured(dataStep1).map((item, index) => (
              <Grid key={index} xs={6} sm={6} md={4} style={{ paddingLeft: 0 }}>
                <div className="label-grid">{item?.label}</div>
                <div
                  className={
                    item?.capitalize
                      ? "capitalize"
                      : item?.label === "Email"
                      ? "break-word"
                      : ""
                  }
                >
                  {item?.value || "-"}
                </div>
              </Grid>
            ))}
          </Grid>
          {!!dataStep1?.under18 && (
            <>
              <Divider className="my-10" />
              <div className="text-color fz-16">Thông tin chủ hợp đồng</div>
              <Grid container spacing={2} className="my-10">
                {infoBuyer(dataStep1).map((item, index) => (
                  <Grid
                    key={index}
                    xs={6}
                    sm={6}
                    md={4}
                    style={{ paddingLeft: 0 }}
                  >
                    <div className="label-grid">{item?.label}</div>
                    <div className={item?.capitalize ? "capitalize" : ""}>
                      {item?.value || "-"}
                    </div>
                  </Grid>
                ))}
              </Grid>
            </>
          )}
          <Divider className="my-10" />
          <div className="text-color fz-16">Thông tin bảo hiểm</div>
          <Grid container spacing={2} className="my-10">
            {infoInsurance(detailData).map((item, index) => (
              <Grid key={index} xs={6} sm={6} md={4} style={{ paddingLeft: 0 }}>
                <div className="label-grid">{item?.label}</div>
                <div>{item?.value || "-"}</div>
              </Grid>
            ))}
          </Grid>
          <Divider className="my-10" />
          <div className="gap-10">
            <div className="text-color fz-16">Quyền lợi bảo hiểm</div>
            <b>1. Quyền lợi chính</b>
            {(detailEdit?.primary || []).map((b, index) => (
              <div key={index}>• {b?.benefitsTitle}</div>
            ))}
            {!_.isEmpty(
              (detailEdit?.additional || []).filter((a) => a.checked === true)
            ) && (
              <>
                <b>2. Quyền lợi bổ sung</b>
                {(detailEdit?.additional || [])
                  .filter((a) => a.checked === true)
                  .map((b, index) => (
                    <div key={index}>• {b?.benefitsTitle}</div>
                  ))}
              </>
            )}

            <Divider className="my-10" />
            <div className={classes.checkbox}>
              <Checkbox
                onClick={(e) => {
                  dispatch({
                    type: "checkAgree",
                    name: "agree1",
                    value: e.target.checked,
                  });
                }}
                checked={!!dataStep3?.agree1}
                className={errors.includes("agree1") ? "err" : ""}
                icon={<BpIcon />}
                checkedIcon={<BpCheckedIcon />}
              />
              <div>
                Tôi cam kết những nội dung trên là đúng sự thật và tôi đã đọc
                hiểu, đồng ý với{" "}
                <a
                  target="_blank"
                  href={`${detailData?.rule_insurance?.url?.[0]}`}
                  className="link"
                >
                  điều kiện, điều khoản
                </a>{" "}
                của hợp đồng bảo hiểm.
              </div>
            </div>
            <div className={classes.checkbox}>
              <Checkbox
                onClick={(e) => {
                  dispatch({
                    type: "checkAgree",
                    name: "agree2",
                    value: e.target.checked,
                  });
                }}
                checked={!!dataStep3?.agree2}
                className={errors.includes("agree2") ? "err" : ""}
                icon={<BpIcon />}
                checkedIcon={<BpCheckedIcon />}
              />
              <div>
                Tôi đồng ý với{" "}
                <a
                  target="_blank"
                  href="https://cdn.globalcare.vn/private/document/pdf/globalcare/2024-05-06-Chinh-sach-bao-mat-du-lieu-ca-nhan.pdf"
                  className="link"
                >
                  “Điều khoản & điều kiện chính sách thu thập, xử lý dữ liệu cá
                  nhân”
                </a>
              </div>
            </div>
            {(errors.includes("agree1") || errors.includes("agree2")) && (
              <div className="err-text">
                Vui lòng đồng ý điều kiện, điều khoản
              </div>
            )}
            <div>
              <span className="note-text">Lưu ý:</span> Khi chọn vào mục này,
              đồng nghĩa việc kê khai bảo hiểm đã xong và quý khách không thể
              cập nhật lại.
            </div>

            <div className="between-row mt-20">
              <div className="text-color">
                Chữ ký của bạn <span className="iconRed">*</span>
              </div>
              <div className="resign" onClick={() => dispatch("clear")}>
                Ký lại
              </div>
            </div>
            <div
              className={
                errors.includes("linkSignImg")
                  ? "w-100 border-sign-err"
                  : "w-100 border-sign"
              }
            >
              <Signature
                clear={clear}
                loading={dataStep3?.loadingImg}
                dispatch={dispatch}
              />
            </div>
            {errors.includes("linkSignImg") && (
              <div className="err-text">Vui lòng ký tên</div>
            )}
            <Divider className="my-10" />
            <div className="between-row mb-10">
              <div className="lg-bold color1">Tổng tiền thanh toán</div>
              <div className="lg-bold fee">
                {formatMoney(detailEdit?.totalFee) || 0} VNĐ
              </div>
            </div>
          </div>
        </div>
      )}

      {currentStep !== 1 && <Divider className="my-10" />}
      <DialogActions>
        <Button
          className="btn outlined"
          onClick={() => {
            dispatch(currentStep === 1 ? "closeOrderDialog" : "prevStep");
            createEventGtag("health_home_order_back_btn");
          }}
        >
          {currentStep === 1 ? (
            "Đóng"
          ) : (
            <>
              <NavigateBeforeIcon />
              Quay lại
            </>
          )}
        </Button>

        <Button
          className="btn next"
          disabled={isButtonDisabled(
            currentStep,
            dataStep1,
            dataStep2,
            dataStep3,
            detailEdit
          )}
          onClick={() => {
            dispatch(currentStep === 3 ? "openOTPDialog" : "nextStep");
            createEventGtag(
              currentStep === 3
                ? "health_home_order_create_before_otp_btn"
                : "health_home_order_next_btn"
            );
          }}
        >
          {currentStep === 3 ? "Thanh toán" : "Tiếp tục"}
          <NavigateNextIcon />
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default OrderDialog;

const ClearInputIcon = ({ name, dispatch }) => (
  <InputAdornment position="end">
    <IconButton
      size="small"
      onClick={() => dispatch({ type: "onChangeStep1", name, value: null })}
    >
      <ClearIcon style={{ fontSize: 14 }} />
    </IconButton>
  </InputAdornment>
);

const TextInput = ({
  type,
  label,
  required = true,
  value,
  onChange,
  className = "",
  onClear,
}) => (
  <div className="textInput">
    <div className="label">
      {label} {required && <span className="iconRed">*</span>}
    </div>
    <TextField
      fullWidth
      type={type ? "tel" : "text"}
      size="small"
      variant="outlined"
      className={className}
      required={required}
      value={value || ""}
      onChange={onChange}
      placeholder="Nhập thông tin"
      InputProps={{
        endAdornment: value ? (
          <InputAdornment position="end">
            <IconButton size="small" onClick={onClear}>
              <ClearIcon style={{ fontSize: 14 }} />
            </IconButton>
          </InputAdornment>
        ) : null,
      }}
    />
  </div>
);

const isButtonDisabled = (
  currentStep,
  dataStep1,
  dataStep2,
  dataStep3,
  detailEdit
) => {
  let isDisabled = false;
  if (currentStep === 1) {
    const keys = [
      "insuredName",
      "insuredGender",
      "insuredDob",
      "insuredPrivateId",
      "insuredEmail",
      "insuredPhone",
      "insuredAddress",
      "beginDate",
    ];
    keys.forEach((i) => {
      if (!dataStep1[i]) {
        isDisabled = true;
      }
    });

    if (!!dataStep1.under18) {
      const keys = [
        "buyerName",
        "buyerGender",
        "buyerDob",
        "buyerPrivateId",
        "buyerEmail",
        "buyerPhone",
        "buyerAddress",
        "relationshipWithBuyerId",
      ];
      keys.forEach((i) => {
        if (!dataStep1[i]) {
          isDisabled = true;
        }
      });
    }

    if (!detailEdit.totalFee) {
      isDisabled = true;
    }
  }
  if (currentStep === 2) {
    (dataStep2?.assessmentQuestion || []).forEach((q, index) => {
      if (![true, false].includes(q?.answer)) {
        isDisabled = true;
      }
    });
  }

  if (currentStep === 3) {
    const keys = ["agree1", "agree2", "linkSignImg"];
    keys.forEach((i) => {
      if (!dataStep3[i]) {
        isDisabled = true;
      }
    });
  }

  return isDisabled;
};
